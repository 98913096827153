import { takeLatest, put, call } from 'redux-saga/effects';
import { ProfileActionTypes } from '../actions/ProfileActionTypes';
import {
  profileRequestSuccess,
  profileRequestError,
  userProfileRequestSuccess,
  userProfileRequestError,
  updateProfileRequestSuccess,
  updateProfileRequestError,
  deleteProfileRequestSuccess,
  deleteProfileRequestError
} from '../actions/profileActions';
import { apiService } from '../../../services/api';

function* profileRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getProfile, req.payload);

    // Dispatch the success action with the API response
    yield put(profileRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(profileRequestError(error.message));
  }
}

function* userProfileRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getUserProfile, req.payload);

    // Dispatch the success action with the API response
    yield put(userProfileRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(userProfileRequestError(error.message));
  }
}

function* updateProfileRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateProfile, req.payload);

    // Dispatch the success action with the API response
    yield put(updateProfileRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateProfileRequestError(error.message));
  }
}

function* deleteProfileRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateProfile, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteProfileRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteProfileRequestError(error.message));
  }
}

export function* watchProfileRequest() {
  yield takeLatest(ProfileActionTypes.PROFILE_REQUEST, profileRequestSaga);
  yield takeLatest(ProfileActionTypes.UPDATE_PROFILE_REQUEST, updateProfileRequestSaga);
  yield takeLatest(ProfileActionTypes.DELETE_PROFILE_REQUEST, deleteProfileRequestSaga);
  yield takeLatest(ProfileActionTypes.USER_PROFILE_REQUEST, userProfileRequestSaga);
}
