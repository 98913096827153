import { EnrollClassActionTypes, EnrollClassAction } from '../actions/EnrollInClassActionTypes';

interface EnrollClassState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: EnrollClassState = {
  data: [],
  loading: false,
  error: null
};

const enrollClassReducer = (state = initialState, action: EnrollClassAction): EnrollClassState => {
  switch (action.type) {
    case EnrollClassActionTypes.ENROLL_CLASS_REQUEST:
      return { ...state, loading: true };
    case EnrollClassActionTypes.ENROLL_CLASS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case EnrollClassActionTypes.ENROLL_CLASS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default enrollClassReducer;
