import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageToDoActionTypes } from '../actions/manageToDoActionTypes';
import {
  manageToDoRequestSuccess,
  manageToDoRequestError,
  manageToDoUpdateRequestSuccess,
  manageToDoDeleteRequestSuccess,
  manageToDoDeleteRequestError,
  manageToDoUpdateRequestError
} from '../actions/manageToDoActions';
import { apiService } from '../../../services/api';

function* manageToDoRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageToDo, req.payload);

    // Dispatch the success action with the API response
    yield put(manageToDoRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageToDoRequestError(error.message));
  }
}

function* manageToDoUpdateRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageToDo, req.payload);

    // Dispatch the success action with the API response
    yield put(manageToDoUpdateRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageToDoUpdateRequestError(error.message));
  }
}

function* manageToDoDeleteRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageToDo, req.payload);

    // Dispatch the success action with the API response
    yield put(manageToDoDeleteRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageToDoDeleteRequestError(error.message));
  }
}

export function* watchManageToDoRequest() {
  yield takeLatest(ManageToDoActionTypes.MANAGE_TODO_REQUEST, manageToDoRequestSaga);
  yield takeLatest(ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST, manageToDoUpdateRequestSaga);
  yield takeLatest(ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST, manageToDoDeleteRequestSaga);
}
