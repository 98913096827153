import { EnrollClassActionTypes, EnrollClassAction } from './EnrollInClassActionTypes';

export const enrollClassRequest = (data: any): EnrollClassAction => ({
  type: EnrollClassActionTypes.ENROLL_CLASS_REQUEST,
  payload: data
});

export const enrollClassRequestSuccess = (data: any): EnrollClassAction => ({
  type: EnrollClassActionTypes.ENROLL_CLASS_REQUEST_SUCCESS,
  payload: data
});

export const enrollClassRequestError = (error: string): EnrollClassAction => ({
  type: EnrollClassActionTypes.ENROLL_CLASS_REQUEST_ERROR,
  payload: error
});
