// features/createClass/actions/createClassActions.ts
import { ManageToDoActionTypes, ManageToDoAction } from './manageToDoActionTypes';

export const manageToDoRequest = (data: any): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_REQUEST,
  payload: data
});

export const manageToDoRequestSuccess = (data: any): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_REQUEST_SUCCESS,
  payload: data
});

export const manageToDoRequestError = (error: string): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_REQUEST_ERROR,
  payload: error
});

export const manageToDoUpdateRequest = (data: any): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST,
  payload: data
});

export const manageToDoUpdateRequestSuccess = (data: any): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST_SUCCESS,
  payload: data
});

export const manageToDoUpdateRequestError = (error: string): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST_ERROR,
  payload: error
});

export const manageToDoDeleteRequest = (data: any): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST,
  payload: data
});

export const manageToDoDeleteRequestSuccess = (data: any): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST_SUCCESS,
  payload: data
});

export const manageToDoDeleteRequestError = (error: string): ManageToDoAction => ({
  type: ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST_ERROR,
  payload: error
});
