export enum ManageToDoActionTypes {
  MANAGE_TODO_REQUEST = 'manageToDo/MANAGE_TODO_REQUEST',
  MANAGE_TODO_REQUEST_SUCCESS = 'manageToDo/MANAGE_TODO_REQUEST_SUCCESS',
  MANAGE_TODO_REQUEST_ERROR = 'manageToDo/MANAGE_TODO_REQUEST_ERROR',
  MANAGE_TODO_UPDATE_REQUEST = 'manageToDo/MANAGE_TODO_UPDATE_REQUEST',
  MANAGE_TODO_UPDATE_REQUEST_SUCCESS = 'manageToDo/MANAGE_TODO_UPDATE_REQUEST_SUCCESS',
  MANAGE_TODO_UPDATE_REQUEST_ERROR = 'manageToDo/MANAGE_TODO_UPDATE_REQUEST_ERROR',
  MANAGE_TODO_DELETE_REQUEST = 'manageToDo/MANAGE_TODO_DELETE_REQUEST',
  MANAGE_TODO_DELETE_REQUEST_SUCCESS = 'manageToDo/MANAGE_TODO_DELETE_REQUEST_SUCCESS',
  MANAGE_TODO_DELETE_REQUEST_ERROR = 'manageToDo/MANAGE_TODO_DELETE_REQUEST_ERROR'
}

export interface ManageToDoAction {
  type: ManageToDoActionTypes;
  payload?: any;
}
