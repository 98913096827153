import { ManageLocationsActionTypes, ManageLocationsAction } from './manageLocationsActionTypes';

export const createLocationsRequest = (data: any): ManageLocationsAction => ({
  type: ManageLocationsActionTypes.CREATE_LOCATIONS,
  payload: data
});

export const createLocationsRequestSuccess = (data: any): ManageLocationsAction => ({
  type: ManageLocationsActionTypes.CREATE_LOCATIONS_SUCCESS,
  payload: data
});

export const createLocationsRequestError = (error: string): ManageLocationsAction => ({
  type: ManageLocationsActionTypes.CREATE_LOCATIONS_ERROR,
  payload: error
});

export const updateLocationsRequest = (data: any): ManageLocationsAction => ({
  type: ManageLocationsActionTypes.UPDATE_LOCATIONS,
  payload: data
});

export const updateLocationsRequestSuccess = (data: any): ManageLocationsAction => ({
  type: ManageLocationsActionTypes.UPDATE_LOCATIONS_SUCCESS,
  payload: data
});

export const updateLocationsRequestError = (error: string): ManageLocationsAction => ({
  type: ManageLocationsActionTypes.UPDATE_LOCATIONS_ERROR,
  payload: error
});
