import { GetCourseLevelsActionTypes, GetCourseLevelsAction } from './getCourseLevelsActionTypes';

export const getCourseLevels = (data: any): GetCourseLevelsAction => ({
  type: GetCourseLevelsActionTypes.GET_COURSE_LEVELS,
  payload: data
});

export const getCourseLevelsSuccess = (data: any): GetCourseLevelsAction => ({
  type: GetCourseLevelsActionTypes.GET_COURSE_LEVELS_SUCCESS,
  payload: data
});

export const getCourseLevelsError = (error: string): GetCourseLevelsAction => ({
  type: GetCourseLevelsActionTypes.GET_COURSE_LEVELS_ERROR,
  payload: error
});
