import { GetClassStudentsAction, GetClassStudentsActionTypes } from './getClassStudentsActionTypes';

export const getClassStudents = (data: any): GetClassStudentsAction => ({
  type: GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST,
  payload: data
});

export const getClassStudentsSuccess = (data: any): GetClassStudentsAction => ({
  type: GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST_SUCCESS,
  payload: data
});

export const getClassStudentsError = (error: string): GetClassStudentsAction => ({
  type: GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST_ERROR,
  payload: error
});
