import { CareersActionTypes, CareersAction } from '../actions/careersActionTypes';

interface CareersState {
  data: any;
  loading: boolean;
  error: string | null;
  updating: boolean;
  updated_data: string[];
  deleting: boolean;
  filtered_data: any[];
  deleted_data: string[];
}

const initialState: CareersState = {
  data: [],
  loading: false,
  error: null,
  updating: false,
  updated_data: [],
  deleting: false,
  deleted_data: [],
  filtered_data: []
};

const careersReducer = (state = initialState, action: CareersAction): CareersState => {
  switch (action.type) {
    case CareersActionTypes.CAREERS_REQUEST:
      return { ...state, loading: true };
    case CareersActionTypes.CAREERS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case CareersActionTypes.CAREERS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CareersActionTypes.CAREERS_UPDATE_REQUEST:
      return { ...state, updating: true };
    case CareersActionTypes.CAREERS_UPDATE_REQUEST_SUCCESS:
      return { ...state, updated_data: action.payload, updating: false, error: null };
    case CareersActionTypes.CAREERS_UPDATE_REQUEST_ERROR:
      return { ...state, updating: false, error: action.payload };

    case CareersActionTypes.CAREERS_DELETE_REQUEST:
      return { ...state, deleting: true };
    case CareersActionTypes.CAREERS_DELETE_REQUEST_SUCCESS:
      return { ...state, deleted_data: action.payload, deleting: false, error: null };
    case CareersActionTypes.CAREERS_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, error: action.payload };
    
    case CareersActionTypes.CAREERS_FILTER_REQUEST: {
        const { employmentType, currentIndex, value, currentJobs, filterField, senioritylevel, salaryRange }:any = action.payload;
        let filteredJobs = [];

        if(state?.filtered_data?.length>0){
          filteredJobs = state?.filtered_data;
        }
        
        if(employmentType.length===0 && senioritylevel.length===0 && salaryRange.length===0  ){
          filteredJobs = currentJobs?.message;
          return {
            ...state,
            filtered_data:currentJobs?.message,
            loading: false,
            error: null,
          };
        }

        switch (filterField) {
          case "employment":
              filteredJobs = [
                ...(currentJobs?.message?.filter((job) => (employmentType.includes(job.employment)) || (senioritylevel.includes(job.seniority)) || (salaryRange.includes(job.salaryRange))) || [])
              ];
            break;
          case "seniority":
              filteredJobs = [
                ...(currentJobs?.message?.filter((job) => (senioritylevel.includes(job.seniority)) || (employmentType.includes(job.employment)) || (salaryRange.includes(job.salaryRange))) || [])
              ];
            break;
          case "salary":
              filteredJobs = [
                  ...(currentJobs?.message?.filter((job) => (salaryRange.includes(job.salaryRange)) || (senioritylevel.includes(job.seniority)) || (employmentType.includes(job.employment))) || [])
              ];
            break;
          default:
            filteredJobs=[]
            break;
        }

        console.log("filteredJobs", filteredJobs)
        return {
          ...state,
          filtered_data: filteredJobs?.length===0?[]: Array?.from(new Set(filteredJobs?.map((job) => job))),
          loading: false,
          error: null,
        };
      }
  
    default:
      return state;
  }
};

export default careersReducer;
