import IManageClassStudent from '../../../models/ManageClassStudent';
import { GetClassStudentsAction, GetClassStudentsActionTypes } from '../actions/getClassStudentsActionTypes';

interface GetClassStudentsState {
  data: IManageClassStudent[];
  loading: boolean;
  error: string | null;
}

const initialState: GetClassStudentsState = {
  data: [],
  loading: false,
  error: null
};

const getClassStudentsReducer = (state = initialState, action: GetClassStudentsAction): GetClassStudentsState => {
  switch (action.type) {
    case GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST:
      return { ...state, loading: true };
    case GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default getClassStudentsReducer;
