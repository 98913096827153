import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const GetImageURL = ({ object_name, presigned, folder }) => {
  const [imageUrl, setImageUrl] = useState('');
  const refreshImage = async () => {
    try {
      if (presigned) {
        const response = await axios.post(
          `https://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_LANE}/getPresignedUrl`,
          {
            object_name: object_name,
            presigned: presigned,
            folder: folder
          }
        );
        const newS3ImageUrl: any = response.data.signed_url;
        setImageUrl(newS3ImageUrl);
      } else {
        const newS3ImageUrl =
          `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3.amazonaws.com/` + folder + '/' + object_name;
        setImageUrl(newS3ImageUrl.replace('.s3.amazonaws.com.s3.amazonaws.com', '.s3.amazonaws.com'));
      }
    } catch (error) {
      console.error('Error refreshing presigned URL:', error);
    }
  };

  useEffect(() => {
    refreshImage(); // Initial refresh on component mount

    // Set up a periodic refresh using setInterval (e.g., every minute)
    const intervalId = setInterval(refreshImage, 60000); // Refresh every minute

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [object_name, presigned, folder]);

  return imageUrl ? imageUrl : '';
};

const RenderImage = ({ object_name, presigned, folder }) => {
  const [imageUrl, setImageUrl] = useState('');
  const refreshImage = async () => {
    try {
      if (presigned) {
        const response = await axios.post(
          `https://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_LANE}/getPresignedUrl`,
          {
            object_name: object_name,
            folder: folder
          }
        );
        const newS3ImageUrl = response.data.signed_url;
        setImageUrl(newS3ImageUrl);
        console.log(newS3ImageUrl);
      } else {
        const newS3ImageUrl =
          `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3.amazonaws.com/` + folder + '/' + object_name;
        setImageUrl(newS3ImageUrl.replace('.s3.amazonaws.com.s3.amazonaws.com', '.s3.amazonaws.com'));
      }
    } catch (error) {
      console.error('Error refreshing presigned URL:', error);
    }
  };

  useEffect(() => {
    refreshImage(); // Initial refresh on component mount

    // Set up a periodic refresh using setInterval (e.g., every minute)
    const intervalId = setInterval(refreshImage, 60000); // Refresh every minute

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [object_name, presigned, folder]);

  return imageUrl ? <img src={imageUrl} alt={object_name} /> : null;
};

export default RenderImage;
