export enum EnrollClassActionTypes {
  ENROLL_CLASS_REQUEST = 'enrollClass/ENROLL_CLASS_REQUEST',
  ENROLL_CLASS_REQUEST_SUCCESS = 'enrollClass/ENROLL_CLASS_REQUEST_SUCCESS',
  ENROLL_CLASS_REQUEST_ERROR = 'enrollClass/ENROLL_CLASS_REQUEST_ERROR'
}

export interface EnrollClassAction {
  type: EnrollClassActionTypes;
  payload?: any;
}
