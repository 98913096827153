import { ClassModelsActionTypes, ClassModelsAction } from '../actions/ClassModelsActionTypes';

interface ClassModelsState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

const initialState: ClassModelsState = {
  data: [],
  loading: false,
  loaded: false,
  error: null
};

const classModelsReducer = (state = initialState, action: ClassModelsAction): ClassModelsState => {
  switch (action.type) {
    case ClassModelsActionTypes.CLASS_MODELS_REQUEST:
      return { ...state, loading: true, loaded: false };
    case ClassModelsActionTypes.CLASS_MODELS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null, loaded: true };
    case ClassModelsActionTypes.CLASS_MODELS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    default:
      return state;
  }
};

export default classModelsReducer;
