import { takeLatest, put, call } from 'redux-saga/effects';
import { CareersActionTypes } from '../actions/careersActionTypes';
import {
  careersRequestSuccess,
  careersRequestError,
  careersUpdateRequestSuccess,
  careersUpdateRequestError,
  careersDeleteRequestSuccess,
  careersDeleteRequestError
} from '../actions/careersActions';
import { apiService } from '../../../services/api';

function* careersRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getCareers, req.payload);

    // Dispatch the success action with the API response
    yield put(careersRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(careersRequestError(error.message));
  }
}

function* careersUpdateRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateCareers, req.payload);

    // Dispatch the success action with the API response
    yield put(careersUpdateRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(careersUpdateRequestError(error.message));
  }
}

function* careersDeleteRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateCareers, req.payload);

    // Dispatch the success action with the API response
    yield put(careersDeleteRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(careersDeleteRequestError(error.message));
  }
}

export function* watchCareersRequest() {
  yield takeLatest(CareersActionTypes.CAREERS_REQUEST, careersRequestSaga);
  yield takeLatest(CareersActionTypes.CAREERS_UPDATE_REQUEST, careersUpdateRequestSaga);
  yield takeLatest(CareersActionTypes.CAREERS_DELETE_REQUEST, careersDeleteRequestSaga);
}
