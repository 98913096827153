import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageLocationsActionTypes } from '../actions/manageLocationsActionTypes';
import {
  createLocationsRequestSuccess,
  createLocationsRequestError,
  updateLocationsRequestSuccess,
  updateLocationsRequestError
} from '../actions/manageLocationsActions';
import { apiService } from '../../../services/api';

function* createLocationsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageLocations, req.payload);

    // Dispatch the success action with the API response
    yield put(createLocationsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(createLocationsRequestError(error.message));
  }
}

function* updateLocationsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageLocations, req.payload);
    console.log(response);

    // Dispatch the success action with the API response
    yield put(updateLocationsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateLocationsRequestError(error.message));
  }
}

export function* watchmanageLocationsRequest() {
  yield takeLatest(ManageLocationsActionTypes.CREATE_LOCATIONS, createLocationsRequestSaga);
  yield takeLatest(ManageLocationsActionTypes.UPDATE_LOCATIONS, updateLocationsRequestSaga);
}
