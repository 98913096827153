import { getHomePageDataActionTypes, getHomePageDataAction } from '../actions/getHomePageDataActionTypes';

interface getHomePageDataState {
  data: string[];
  loading: boolean;
  load_error: string | null;
}

const initialState: getHomePageDataState = {
  data: [],
  loading: false,
  load_error: null
};

const homePageDataReducer = (state = initialState, action: getHomePageDataAction): getHomePageDataState => {
  switch (action.type) {
    case getHomePageDataActionTypes.GET_HOME_PAGE_DATA:
      return { ...state, loading: true };
    case getHomePageDataActionTypes.GET_HOME_PAGE_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false, load_error: null };
    case getHomePageDataActionTypes.GET_HOME_PAGE_DATA_ERROR:
      return { ...state, loading: false, load_error: action.payload };
    default:
      return state;
  }
};

export default homePageDataReducer;
