import axios from 'axios';

interface UploadObjectParams {
  file: File | Blob;
  fileName: string;
  image_type?: string;
}

export const uploadBlobObject = async ({
  file,
  fileName,
  image_type = 'resource'
}: UploadObjectParams): Promise<string> => {
  if (!file) {
    throw new Error('Please select a file');
  }

  // Check file type and size
  const isValidFileType = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(file.type);
  const isValidFileSize = file.size <= 10000000; // 10MB limit

  if (!isValidFileType || !isValidFileSize) {
    throw new Error('Invalid file type or file size');
  }

  try {
    const post_url = `https://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_LANE}/getPresignedUrl`;
    const response = await axios.post(post_url, {
      params: { fileName, image_type },
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const { url } = response.data;

    if (!url) {
      throw new Error('Failed to get upload URL');
    }

    // Step 2: Upload file to pre-signed URL
    let content_type = file.type === 'application/pdf' ? 'application/pdf' : 'application/octet-stream';

    await axios.put(url, file, {
      headers: {
        'Content-Type': content_type
      }
    });

    // Return the public URL of the uploaded file
    // This assumes the URL structure. Adjust if needed based on your S3 configuration
    const publicUrl = url.split('?')[0];
    return publicUrl;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw new Error('Error uploading file');
  }
};

export const uploadObject = async ({ file, image_type, upload = false }) => {
  console.log(file);
  if (!file) {
    alert('Please select a file');
    return;
  } else {
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg' &&
      file.type !== 'image/png' &&
      file.size <= 100000 &&
      file.type !== 'application/pdf' &&
      file.type !== 'application/msword' &&
      file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      file.type !== 'video/mp4' &&
      file.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      alert('Invalid file type or file size');
    } else {
      try {
        const response = await axios.post(
          `https://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_LANE}/getPresignedUrl`,
          {
            params: { fileName: file.name, image_type: image_type },
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        const { url } = response.data;

        if (upload) {
          if (url) {
            let content_type;
            if (file.type === 'application/pdf') {
              content_type = 'application/pdf';
            } else if (file.type === 'application/msword') {
              content_type = 'application/msword';
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              content_type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            } else if (file.type === 'video/mp4') {
              content_type = 'binary/octet-stream';
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
              content_type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            } else {
              content_type = 'application/octet-stream';
            }
            await axios.put(url, file, {
              headers: {
                'Content-Type': content_type
              }
            });
            return url;
          } else {
            return 'error';
          }
        } else {
          return url;
        }

        // Use the pre-signed URL to upload the file directly to S3
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file');
      }
    }
  }
};
