import { ManageToDoActionTypes, ManageToDoAction } from '../actions/manageToDoActionTypes';

interface ManageToDoState {
  data: string[];
  loading: boolean;
  error: string | null;
  updating: boolean;
  deleting: boolean;
}

const initialState: ManageToDoState = {
  data: [],
  loading: false,
  error: null,
  updating: false,
  deleting: false
};

const manageToDoReducer = (state = initialState, action: ManageToDoAction): ManageToDoState => {
  switch (action.type) {
    case ManageToDoActionTypes.MANAGE_TODO_REQUEST:
      return { ...state, updating: true };
    case ManageToDoActionTypes.MANAGE_TODO_REQUEST_SUCCESS:
      return { ...state, data: action.payload, updating: false, error: null };
    case ManageToDoActionTypes.MANAGE_TODO_REQUEST_ERROR:
      return { ...state, updating: false, error: action.payload };

    case ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST:
      return { ...state, loading: true };
    case ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ManageToDoActionTypes.MANAGE_TODO_UPDATE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST:
      return { ...state, deleting: true };
    case ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, deleting: false, error: null };
    case ManageToDoActionTypes.MANAGE_TODO_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, error: action.payload };

    default:
      return state;
  }
};

export default manageToDoReducer;
