export enum getHomePageDataActionTypes {
  GET_HOME_PAGE_DATA = 'getHomePageData/GET_HOME_PAGE_DATA',
  GET_HOME_PAGE_DATA_SUCCESS = 'getHomePageData/GET_HOME_PAGE_DATA_SUCCESS',
  GET_HOME_PAGE_DATA_ERROR = 'getHomePageData/GET_HOME_PAGE_DATA_ERROR'
}

export interface getHomePageDataAction {
  type: getHomePageDataActionTypes;
  payload?: any;
}
