export enum getLocationsActionTypes {
  GET_LOCATIONS = 'getLocations/GET_LOCATIONS',
  GET_LOCATIONS_SUCCESS = 'getLocations/GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_ERROR = 'getLocations/GET_LOCATIONS_ERROR'
}

export interface getLocationsAction {
  type: getLocationsActionTypes;
  payload?: any;
}
