export enum RequestHomeworkActionTypes {
  REQUEST_HOMEWORK = 'requestHomework/REQUEST_HOMEWORK',
  REQUEST_HOMEWORK_SUCCESS = 'requestHomework/REQUEST_HOMEWORK_SUCCESS',
  REQUEST_HOMEWORK_ERROR = 'requestHomework/REQUEST_HOMEWORK_ERROR'
}

export interface RequestHomeworkAction {
  type: RequestHomeworkActionTypes;
  payload?: any;
}
