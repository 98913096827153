import { RequestHomeworkActionTypes, RequestHomeworkAction } from '../actions/requestHomeworkActionTypes';

interface RequestHomeworkState {
  data: string[];
  requesting: boolean;
  request_error: string | null;
}

const initialState: RequestHomeworkState = {
  data: [],
  requesting: false,
  request_error: null
};

const requestHomeworkReducer = (state = initialState, action: RequestHomeworkAction): RequestHomeworkState => {
  switch (action.type) {
    case RequestHomeworkActionTypes.REQUEST_HOMEWORK:
      return { ...state, requesting: true };
    case RequestHomeworkActionTypes.REQUEST_HOMEWORK_SUCCESS:
      return { ...state, data: action.payload, requesting: false, request_error: null };
    case RequestHomeworkActionTypes.REQUEST_HOMEWORK_ERROR:
      return { ...state, requesting: false, request_error: action.payload };
    default:
      return state;
  }
};

export default requestHomeworkReducer;
