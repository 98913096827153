import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

function isSafari() {
  const ua = navigator.userAgent;
  return ua.includes("Safari") && !ua.includes("Chrome");
}

let messaging: any;

const firebaseConfig = {
  apiKey: "AIzaSyCS3GtxfIaJyjbA__NOENlQASoFyVd5Ke8",
  authDomain: "gen3learning-adb88.firebaseapp.com",
  projectId: "gen3learning-adb88",
  storageBucket: "gen3learning-adb88.appspot.com",
  messagingSenderId: "420189689272",
  appId: "1:420189689272:web:63992a4beb44576f1feb4d",
  measurementId: "G-Q2HQNGVDLW",
};

if (!isSafari()) {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  messaging = getMessaging(app);
}

export const requestPermission = async () => {
  if (!messaging) return null;
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BDEtuxnywFG1nfxTrEncg6Fa6uxlWqi9VMY6ESNPueyg2-DT-hYFVYc0xXVZatae6SQBUmXCqe_ErpQuXe0wFW4",
    });
    console.log("FCM Token:", token);
    localStorage.setItem("fcmToken", token);

    return token;
  } catch (error) {
    console.error("Permission denied", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!messaging) return null;
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

if (!isSafari() && "serviceWorker" in navigator) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log("Service Worker registered with scope:", registration.scope);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
}
