// features/createClass/actions/createClassActions.ts
import { getLocationsActionTypes, getLocationsAction } from './getLocationsActionTypes';

export const getLocationsRequest = (data: any): getLocationsAction => ({
  type: getLocationsActionTypes.GET_LOCATIONS,
  payload: data
});

export const getLocationsRequestSuccess = (data: any): getLocationsAction => ({
  type: getLocationsActionTypes.GET_LOCATIONS_SUCCESS,
  payload: data
});

export const getLocationsRequestError = (error: string): getLocationsAction => ({
  type: getLocationsActionTypes.GET_LOCATIONS_ERROR,
  payload: error
});
