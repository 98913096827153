import { takeLatest, put, call } from 'redux-saga/effects';
import { getHomePageDataActionTypes } from '../actions/getHomePageDataActionTypes';
import { getHomePageDataRequestSuccess, getHomePageDataRequestError } from '../actions/getHomePageDataActions';
import { apiService } from '../../../services/api';

function* getHomePageDataRequestSaga() {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.gen3HomePage);

    // Dispatch the success action with the API response
    yield put(getHomePageDataRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getHomePageDataRequestError(error.message));
  }
}

export function* watchgetHomePageDataRequest() {
  yield takeLatest(getHomePageDataActionTypes.GET_HOME_PAGE_DATA, getHomePageDataRequestSaga);
}
