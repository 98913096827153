export enum CareersActionTypes {
  CAREERS_REQUEST = 'careers/CAREERS_REQUEST',
  CAREERS_REQUEST_SUCCESS = 'careers/CAREERS_REQUEST_SUCCESS',
  CAREERS_REQUEST_ERROR = 'careers/CAREERS_REQUEST_ERROR',
  CAREERS_UPDATE_REQUEST = 'careers/CAREERS_UPDATE_REQUEST',
  CAREERS_UPDATE_REQUEST_SUCCESS = 'careers/CAREERS_UPDATE_REQUEST_SUCCESS',
  CAREERS_UPDATE_REQUEST_ERROR = 'careers/CAREERS_UPDATE_REQUEST_ERROR',
  CAREERS_DELETE_REQUEST = 'careers/CAREERS_DELETE_REQUEST',
  CAREERS_DELETE_REQUEST_SUCCESS = 'careers/CAREERS_DELETE_REQUEST_SUCCESS',
  CAREERS_DELETE_REQUEST_ERROR = 'careers/CAREERS_DELETE_REQUEST_ERROR',
  CAREERS_FILTER_REQUEST = 'careers/CAREERS_FILTER_REQUEST',
  CAREERS_FILTER_SENIORITY_REQUEST = 'careers/CAREERS_FILTER_SENIORITY_REQUEST'
}

export interface CareersAction {
  type: CareersActionTypes;
  payload?: any;
}
