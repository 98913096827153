import { UserTasksActionTypes, UserTasksAction } from '../actions/userTasksActionTypes';

interface UserTasksState {
  data: string[];
  loading: boolean;
  error: string | null;
  updating: boolean;
  deleting: boolean;
}

const initialState: UserTasksState = {
  data: [],
  loading: false,
  error: null,
  updating: false,
  deleting: false
};

const userTasksReducer = (state = initialState, action: UserTasksAction): UserTasksState => {
  switch (action.type) {
    case UserTasksActionTypes.USER_TASKS_REQUEST:
      return { ...state, updating: true };
    case UserTasksActionTypes.USER_TASKS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, updating: false, error: null };
    case UserTasksActionTypes.USER_TASKS_REQUEST_ERROR:
      return { ...state, updating: false, error: action.payload };

    case UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST:
      return { ...state, loading: true };
    case UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST_SUCCESS:
      return { ...state, data: action.payload,loading: false, error: null };
    case UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case UserTasksActionTypes.USER_TASKS_DELETE_REQUEST:
      return { ...state, deleting: true };
    case UserTasksActionTypes.USER_TASKS_DELETE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, deleting: false, error: null };
    case UserTasksActionTypes.USER_TASKS_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, error: action.payload };

    default:
      return state;
  }
};

export default userTasksReducer;
