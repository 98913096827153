import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { blue } from '@mui/material/colors';
//import './confirmation.css'

interface ConfirmationModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, onConfirm, onCancel, message }) => {
  const [confirmationText, setConfirmationText] = useState('');

  return (
    <Modal
      open={open}
      onClose={onCancel} // Close the modal on cancel
      aria-labelledby="confirmation-modal-title"
      BackdropProps={{
        style: { backgroundColor: 'transparent' }, // Set backdrop to transparent
      }}
    >
      <Box
        p={3}
        bgcolor="background.paper"
        boxShadow={1}
        borderRadius={2}
        style={{ width: 400, margin: '20vh auto'}}
      >
        <Typography variant="h6" id="confirmation-modal-title" mb={2}>
          {message}
        </Typography>
       
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onConfirm}
          sx={{ mb: 2 }}
        >
          Confirm
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
