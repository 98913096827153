// features/createClass/actions/createClassActions.ts
import { CareersApplyActionTypes, CareersApplyAction } from './careersApplyActionTypes';

export const careersApplyRequest = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_REQUEST,
  payload: data
});

export const careersApplyRequestSuccess = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_REQUEST_SUCCESS,
  payload: data
});

export const careersApplyRequestError = (error: string): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_REQUEST_ERROR,
  payload: error
});
export const careersApplyUpdateRequest = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST,
  payload: data
});

export const careersApplyUpdateRequestSuccess = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST_SUCCESS,
  payload: data
});

export const careersApplyUpdateRequestError = (error: string): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST_ERROR,
  payload: error
});
export const careersApplyDeleteRequest = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST,
  payload: data
});

export const careersApplyDeleteRequestSuccess = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST_SUCCESS,
  payload: data
});

export const careersApplyDeleteRequestError = (error: string): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST_ERROR,
  payload: error
});
export const careersApplyMessageRequest = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_MESSAGE_REQUEST,
  payload: data
});

export const careersApplyMessageRequestSuccess = (data: any): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_MESSAGE_REQUEST_SUCCESS,
  payload: data
});

export const careersApplyMessageRequestError = (error: string): CareersApplyAction => ({
  type: CareersApplyActionTypes.CAREERS_APPLY_MESSAGE_REQUEST_ERROR,
  payload: error
});
    