interface ScheduleData {
  day: string;
  startTime: string;
  endTime: string;
}

interface ClassItem {
  name: string;
  start_time: string;
  end_time: string;
  day: string;
}

interface StudentCourse {
  name: string;
  start_time: string;
  end_time: string;
  day: string;
}

interface AvailableSlot {
  startTime: string;
  endTime: string;
  day: string;
}

interface CheckClassOverlapResult {
  availableSlots: AvailableSlot[];
  clashingCourses: string[];
}

export const formatTime = (timeStr: any) => {
  if (!timeStr || timeStr === undefined) {
    return "";
  }

  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (
    (modifier === "P.M" || modifier === "PM" || modifier === "P.M.") &&
    hours !== "12"
  ) {
    hours = parseInt(hours, 10) + 12;
  } else if (
    (modifier === "A.M" || modifier === "AM" || modifier === "A.M.") &&
    hours === "12"
  ) {
    hours = "00";
  }
  return `${hours}:${minutes}`;
};

export const format12to24 = (timeStr: string) => {
  const [time, modifier] = timeStr?.split(" ");
  let [hours, minutes] = time.split(":");
  let hoursInt = parseInt(hours, 10);
  if (
    (modifier === "PM" || modifier === "P.M" || modifier === "P.M.") &&
    hoursInt !== 12
  ) {
    hoursInt += 12;
  } else if (
    (modifier === "AM" || modifier === "A.M" || modifier === "A.M.") &&
    hoursInt === 12
  ) {
    hoursInt = 0;
  }
  const formattedHours = hoursInt.toString().padStart(2, "0");
  return `${formattedHours}:${minutes}`;
};

export function format24to12(time24) {
  if (time24?.includes("AM") || time24?.includes("PM")) {
    return time24;
  }
  // covert 24 to 12 hour format
  const [hours, minutes] = time24.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12 AM
  const minutesFormatted = minutes.toString().padStart(2, "0");
  return `${hours12}:${minutesFormatted} ${period}`;
}

export const convertToMinutes = (timeStr) => {
  const [hours, minutes] = timeStr?.split(":").map(Number);
  return hours * 60 + minutes;
};

const minutesToTimeString = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

// give subtracted time and name of courses that overlapped
export function checkClassOverlap(
  scheduleData: any,
  classList: any,
  studentList: any = []
) {
  const {
    day: scheduledDay,
    startTime: scheduledStart,
    endTime: scheduledEnd,
  } = scheduleData;

  const scheduledStartInMinutes = convertToMinutes(scheduledStart);
  const scheduledEndInMinutes = convertToMinutes(scheduledEnd);
  let availableSlots = [
    { start: scheduledStartInMinutes, end: scheduledEndInMinutes },
  ];

  const teacherClashingCourses: any = [];
  const studentClashingCourses: any = [];
  for (const classItem of classList) {
    const { start_time: classStart, end_time: classEnd } = classItem;
    const classStartInMinutes = convertToMinutes(formatTime(classStart));
    const classEndInMinutes = convertToMinutes(formatTime(classEnd));

    if (scheduledDay === classItem.day) {
      if (
        classStartInMinutes < scheduledEndInMinutes &&
        classEndInMinutes > scheduledStartInMinutes
      ) {
        teacherClashingCourses.push(classItem);
      }

      availableSlots = availableSlots.reduce((acc: any, slot: any) => {
        if (
          classStartInMinutes <= slot.start &&
          classEndInMinutes >= slot.end
        ) {
          return acc;
        } else if (
          classStartInMinutes < slot.end &&
          classEndInMinutes > slot.start
        ) {
          if (classStartInMinutes > slot.start) {
            acc.push({ start: slot.start, end: classStartInMinutes });
          }
          if (classEndInMinutes < slot.end) {
            acc.push({ start: classEndInMinutes, end: slot.end });
          }
        } else {
          acc.push(slot);
        }
        return acc;
      }, []);
    }
  }

  for (const studentItem of studentList) {
    const { start_time: studentStart, end_time: studentEnd } = studentItem;
    console.log("calling formatTime for studentStart");
    const studentStartInMinutes = convertToMinutes(formatTime(studentStart));
    console.log("calling formatTime for studentEnd");
    const studentEndInMinutes = convertToMinutes(formatTime(studentEnd));

    if (scheduledDay === studentItem.day) {
      if (
        studentStartInMinutes < scheduledEndInMinutes &&
        studentEndInMinutes > scheduledStartInMinutes
      ) {
        studentClashingCourses.push(studentItem);
      }
      availableSlots = availableSlots.reduce((acc: any, slot: any) => {
        if (
          studentStartInMinutes <= slot.start &&
          studentEndInMinutes >= slot.end
        ) {
          return acc;
        } else if (
          studentStartInMinutes < slot.end &&
          studentEndInMinutes > slot.start
        ) {
          if (studentStartInMinutes > slot.start) {
            acc.push({ start: slot.start, end: studentStartInMinutes });
          }
          if (studentEndInMinutes < slot.end) {
            acc.push({ start: studentEndInMinutes, end: slot.end });
          }
        } else {
          acc.push(slot);
        }
        return acc;
      }, []);
    }
  }

  return {
    slot: availableSlots.map((slot) => ({
      startTime: minutesToTimeString(slot.start),
      endTime: minutesToTimeString(slot.end),
      day: scheduledDay,
    })),
    teacherClashingCourses: teacherClashingCourses,
    studentClashingCourses: studentClashingCourses,
  };
}

// Just checking overlapping between slot from provided list.
export function checkAvailabilityOverlap(scheduleData, classList) {
  const {
    day: scheduledDay,
    startTime: scheduledStart,
    endTime: scheduledEnd,
  } = scheduleData;
  const scheduledStartInMinutes = convertToMinutes(scheduledStart);
  const scheduledEndInMinutes = convertToMinutes(scheduledEnd);

  let overlapExists = false;

  for (const classItem of classList) {
    const { start_time: classStart, end_time: classEnd } = classItem;
    const classStartInMinutes = convertToMinutes(formatTime(classStart));
    const classEndInMinutes = convertToMinutes(formatTime(classEnd));

    if (scheduledDay === classItem.day) {
      if (
        classStartInMinutes < scheduledEndInMinutes &&
        classEndInMinutes > scheduledStartInMinutes
      ) {
        overlapExists = true;
        break;
      }
    }
  }

  return overlapExists;
}

export const convertMinutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = mins < 10 ? `0${mins}` : mins;
  return `${formattedHours}:${formattedMinutes}`;
};

export const getAvailableIntervals = (
  start: number,
  end: number,
  duration: number
): number[] => {
  let intervals: any = [];
  let currentStartTime = start;

  while (currentStartTime + duration <= end) {
    intervals.push(currentStartTime);
    currentStartTime += 15;
  }

  return intervals;
};

export const sortByDayAndTime = (weekdays: any) => {
  const dayOrder = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  weekdays.sort((a: any, b: any) => {
    const dayA = dayOrder.indexOf(a.schedule_data.day);
    const dayB = dayOrder.indexOf(b.schedule_data.day);

    if (dayA !== dayB) {
      return dayA - dayB;
    }
    const startTimeA = convertToMinutes(a.schedule_data.startTime);
    const startTimeB = convertToMinutes(b.schedule_data.startTime);
    return startTimeA - startTimeB;
  });

  return weekdays;
};
