import { takeLatest, put, call } from 'redux-saga/effects';
import { GetCourseLevelsActionTypes } from '../actions/getCourseLevelsActionTypes';
import { getCourseLevelsSuccess, getCourseLevelsError } from '../actions/getCourseLevelsActions';
import { apiService } from '../../../services/api';

function* getCourseLevelSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getCourseLevels, req.payload);

    // Dispatch the success action with the API response
    yield put(getCourseLevelsSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getCourseLevelsError(error.message));
  }
}

export function* watchgetCourseLevels() {
  yield takeLatest(GetCourseLevelsActionTypes.GET_COURSE_LEVELS, getCourseLevelSaga);
}
