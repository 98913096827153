// features/createClass/actions/createClassActions.ts
import { CareersActionTypes, CareersAction } from './careersActionTypes';

export const careersRequest = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_REQUEST,
  payload: data
});

export const careersRequestSuccess = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_REQUEST_SUCCESS,
  payload: data
});

export const careersRequestError = (error: string): CareersAction => ({
  type: CareersActionTypes.CAREERS_REQUEST_ERROR,
  payload: error
});
export const careersUpdateRequest = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_UPDATE_REQUEST,
  payload: data
});

export const careersUpdateRequestSuccess = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_UPDATE_REQUEST_SUCCESS,
  payload: data
});

export const careersUpdateRequestError = (error: string): CareersAction => ({
  type: CareersActionTypes.CAREERS_UPDATE_REQUEST_ERROR,
  payload: error
});
export const careersDeleteRequest = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_DELETE_REQUEST,
  payload: data
});

export const careersDeleteRequestSuccess = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_DELETE_REQUEST_SUCCESS,
  payload: data
});

export const careersDeleteRequestError = (error: string): CareersAction => ({
  type: CareersActionTypes.CAREERS_DELETE_REQUEST_ERROR,
  payload: error
});

export const careersFilterRequest = (data: any): CareersAction => ({
  type: CareersActionTypes.CAREERS_FILTER_REQUEST,
  payload: data
});
