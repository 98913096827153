import { GetCourseLevelsActionTypes, GetCourseLevelsAction } from '../actions/getCourseLevelsActionTypes';

interface GetCourseLevelState {
  data: string[];
  requesting: boolean;
  request_error: string | null;
}

const initialState: GetCourseLevelState = {
  data: [],
  requesting: false,
  request_error: null
};

const getCourseLevelsReducer = (state = initialState, action: GetCourseLevelsAction): GetCourseLevelState => {
  switch (action.type) {
    case GetCourseLevelsActionTypes.GET_COURSE_LEVELS:
      return { ...state, requesting: true };
    case GetCourseLevelsActionTypes.GET_COURSE_LEVELS_SUCCESS:
      return { ...state, data: action.payload, requesting: false, request_error: null };
    case GetCourseLevelsActionTypes.GET_COURSE_LEVELS_ERROR:
      return { ...state, requesting: false, request_error: action.payload };
    default:
      return state;
  }
};

export default getCourseLevelsReducer;
