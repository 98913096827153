export enum GetClassStudentsActionTypes {
  CLASS_STUDENTS_REQUEST = 'getClassStudentsActionTypes/CLASS_STUDENTS_REQUEST',
  CLASS_STUDENTS_REQUEST_SUCCESS = 'getClassStudentsActionTypes/CLASS_STUDENTS_REQUEST_SUCCESS',
  CLASS_STUDENTS_REQUEST_ERROR = 'getClassStudentsActionTypes/CLASS_STUDENTS_REQUEST_ERROR'
}

export interface GetClassStudentsAction {
  type: GetClassStudentsActionTypes;
  payload?: any;
}
