import { takeLatest, put, call } from 'redux-saga/effects';
import { RequestHomeworkActionTypes } from '../actions/requestHomeworkActionTypes';
import { requestHomeworkSuccess, requestHomeworkError } from '../actions/requestHomeworkActions';
import { apiService } from '../../../services/api';

function* requestHomeworkSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.requestHomework, req.payload);

    // Dispatch the success action with the API response
    yield put(requestHomeworkSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(requestHomeworkError(error.message));
  }
}

export function* watchrequestHomework() {
  yield takeLatest(RequestHomeworkActionTypes.REQUEST_HOMEWORK, requestHomeworkSaga);
}
