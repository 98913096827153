import { takeLatest, put, call } from 'redux-saga/effects';
import { getLocationsActionTypes } from '../actions/getLocationsActionTypes';
import { getLocationsRequestSuccess, getLocationsRequestError } from '../actions/getLocationsActions';
import { apiService } from '../../../services/api';

function* getLocationsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getLocations, req.payload);

    // Dispatch the success action with the API response
    yield put(getLocationsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getLocationsRequestError(error.message));
  }
}

export function* watchgetLocationsRequest() {
  yield takeLatest(getLocationsActionTypes.GET_LOCATIONS, getLocationsRequestSaga);
}
