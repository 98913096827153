import { MarkClassAttendanceAction, MarkClassAttendanceActionTypes } from './markClassAttendanceActionTypes';

export const markClassAttendance = (data: any): MarkClassAttendanceAction => ({
  type: MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST,
  payload: data
});

export const markClassAttendanceSuccess = (data: any): MarkClassAttendanceAction => ({
  type: MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST_SUCCESS,
  payload: data
});

export const markClassAttendanceError = (error: string): MarkClassAttendanceAction => ({
  type: MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST_ERROR,
  payload: error
});
