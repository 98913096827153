// features/login/sagas/loginSaga.ts
import { takeLatest, put, call } from 'redux-saga/effects';
import { AdminRegisterActionTypes } from '../actions/AdminRegisterUserActionTypes';
import {
  adminRegisterUserSuccess,
  adminRegisterUserError,
  adminListUserError,
  adminListUserSuccess,
  teacherRegisterStudentError,
  teacherRegisterStudentSuccess
} from '../actions/adminRegisterUserActions';
import { apiService } from '../../../services/api';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });

function* adminRegisterUserSaga(action) {
  try {
    const req = action.payload;
    // Simulate an asynchronous API call
    localStorage.getItem('client_id');
    const client_id = localStorage.getItem('client_id');
    const groups = req.groups;
    const cognito_user_pool_id = localStorage.getItem('userPool');
    const registering_user_name = req.email_address.split('@')[0];
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: localStorage.getItem('identity_pool_id')
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: client_id,
      Password: req.password,
      Username: registering_user_name,
      UserAttributes: [
        {
          Name: 'email',
          Value: req.email_address
        },
        {
          Name: 'phone_number',
          Value: '+' + req.phone_number
        },
        {
          Name: 'name',
          Value: req.first_name + ' ' + req.last_name
        },
        {
          Name: 'family_name',
          Value: req.last_name
        },
        {
          Name: 'given_name',
          Value: req.first_name
        },
        {
          Name: 'preferred_username',
          Value: req.email.split('@')[0]
        }
      ]
    };
    const authPromise = new Promise((resolve, reject) => {
      cognito.signUp(params, (err, data) => {
        if (err) {
          console.error('Error signing up user:', err);
        } else {
          for (var group_index in groups) {
            const addUserParams = {
              GroupName: groups[group_index],
              Username: registering_user_name,
              UserPoolId: cognito_user_pool_id
            };

            cognito.adminAddUserToGroup(addUserParams, (addGroupErr, addGroupData) => {
              if (addGroupErr) {
                console.error('Error adding user to group:', addGroupErr);
              } else {
                console.log('User added to group successfully:', addGroupData);
              }
            });
          }
        }
      });
    });
    try {
      const authResult = yield authPromise;
      yield put(adminRegisterUserSuccess(authResult));
    } catch (error: any) {
      yield put(adminRegisterUserError(error));
    }
  } catch (error: any) {
    yield put(adminRegisterUserError(error));
  }
}

function* adminListUserSaga() {
  try {
    // Simulate an asynchronous API call
    localStorage.getItem('client_id');
    const cognito_user_pool_id = localStorage.getItem('userPool');
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: localStorage.getItem('identity_pool_id')
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const authPromise = new Promise((resolve, reject) => {
      var user_list_data: any[] = [];
      cognito.listUsers(
        {
          UserPoolId: cognito_user_pool_id
        },
        (err, data) => {
          if (err) {
            console.error('Error listing users:', err);
          } else {
            // Iterate through each user
            data.Users?.forEach((user) => {
              const username = user.Username;
              var user_attribute_details = {};
              var user_attribute_details_list: any[] = [];

              cognito.adminGetUser(
                {
                  Username: username,
                  UserPoolId: cognito_user_pool_id
                },
                (userErr, userData) => {
                  if (userErr) {
                    console.error(`Error getting groups for user ${username}:`, userErr);
                  } else {
                    userData.UserAttributes.forEach((name_value, index) => {
                      user_attribute_details[name_value.Name] = name_value.Value;
                    });
                    user_attribute_details['user_status'] = userData.UserStatus;
                    user_attribute_details['user_enabled'] = userData.Enabled;
                    user_attribute_details_list.push(user_attribute_details);
                    user_attribute_details = {};
                  }
                }
              );

              // Get the groups for each user
              cognito.adminListGroupsForUser(
                {
                  Username: username,
                  UserPoolId: cognito_user_pool_id
                },
                (groupErr, groupData) => {
                  if (groupErr) {
                    console.error(`Error getting groups for user ${username}:`, groupErr);
                  } else {
                    const groups = groupData.Groups?.map((group) => group.GroupName);
                    console.log(`User ${username} is in groups:`, groups);
                    groups?.forEach((user_group) => {
                      const user_data: any = {
                        username: username,
                        groups: user_group
                      };
                      user_attribute_details_list.forEach((name_value, index) => {
                        Object.assign(user_data, name_value);
                      });
                      user_list_data.push(user_data);
                    });
                    resolve(user_list_data);
                  }
                }
              );
            });
          }
        }
      );
    });
    try {
      const authResult = yield authPromise;
      yield put(adminListUserSuccess(authResult));
    } catch (error: any) {
      yield put(adminListUserError(error));
    }
  } catch (error: any) {
    yield put(adminListUserError(error));
  }
}

function* teacherRegisterStudentSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.teacherRegisterStudent, req.payload);

    // Dispatch the success action with the API response
    yield put(teacherRegisterStudentSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(teacherRegisterStudentError(error.message));
  }
}

export function* watchAdminRegisterUser() {
  yield takeLatest(AdminRegisterActionTypes.ADMIN_REGISTER_USER, adminRegisterUserSaga);
  yield takeLatest(AdminRegisterActionTypes.ADMIN_LIST_USER, adminListUserSaga);
  yield takeLatest(AdminRegisterActionTypes.TEACHER_REGISTER_STUDENT, teacherRegisterStudentSaga);
}
