// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { Suspense } from 'react';

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<div></div>}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
