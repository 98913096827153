export enum MarkClassAttendanceActionTypes {
  MARK_ATTENDANCE_REQUEST = 'markClassAttendanceActionTypes/MARK_ATTENDANCE_REQUEST',
  MARK_ATTENDANCE_REQUEST_SUCCESS = 'markClassAttendanceActionTypes/MARK_ATTENDANCE_REQUEST_SUCCESS',
  MARK_ATTENDANCE_REQUEST_ERROR = 'markClassAttendanceActionTypes/MARK_ATTENDANCE_REQUEST_ERROR'
}

export interface MarkClassAttendanceAction {
  type: MarkClassAttendanceActionTypes;
  payload?: any;
}
