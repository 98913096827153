import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { BookingsActionTypes } from '../actions/bookingsActionTypes';
import { bookingsError, bookingsSuccess } from '../actions/bookingsActions';

function* bookingsSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.bookResource, req.payload);
    console.log(response);
    yield put(bookingsSuccess(response));
  } catch (error: any) {
    yield put(bookingsError(error.message));
  }
}

export function* watchBookings() {
  yield takeLatest(BookingsActionTypes.BOOKINGS, bookingsSaga);
}
