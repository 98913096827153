import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { co } from '@fullcalendar/core/internal-common';

interface RichTextEditorProps {
  readOnly?: boolean;
  content?: string;
  onChange?: (content: string) => void;
}

const RichTextEditor = forwardRef(({ readOnly = false, content = '', onChange }: RichTextEditorProps, ref) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      if (!quillRef.current) {
        quillRef.current = new Quill(editorRef.current, {
          theme: readOnly ? '' : 'snow',
          readOnly: readOnly,
          modules: {
            toolbar: readOnly
              ? false
              : [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline'],
                  ['link', 'image'],
                  [{ list: 'ordered' }, { list: 'bullet' }]
                ]
          }
        });
        // Set the initial content if provided
        if (content && quillRef.current) {
          quillRef.current.root.innerHTML = content;
        }

        // Handle content change
        quillRef.current.on('text-change', () => {
          if (onChange && quillRef.current) {
            onChange(quillRef.current.root.innerHTML);
          }
        });

        if (readOnly) {
          quillRef.current.root.style.border = 'none';
          quillRef.current.root.style.padding = '0';
          quillRef.current.root.style.backgroundColor = 'transparent';
          quillRef.current.root.style.height = 'auto';
          quillRef.current.root.style.fontSize = '16px';
          quillRef.current.root.style.fontFamily = 'Poppins, sans-serif';
        }
      }
    }
  }, [readOnly, content, onChange]);

  

  useImperativeHandle(ref, () => ({
    getEditorContent: () => {
      return quillRef.current?.root.innerHTML || '';
    }
  }));

  useEffect(() => {
    if (quillRef.current && quillRef.current.root.innerHTML !== content) {
      quillRef.current.root.innerHTML = content || '';
    }
  }, [content]);
  return <div ref={editorRef} style={{ height: readOnly ? 'auto' : '300px' }} />;
});

export default RichTextEditor;
