import { combineReducers } from 'redux';
import { Gen3LearningReducer } from '../features/otherReducers/Gen3LearningReducer';
import loginReducer from '../features/login/reducers/loginReducer';
import courseListingReducer from '../features/courseListing/reducers/courseListingReducer';
import userMetricsReducer from '../features/userMetrics/reducers/userMetricsReducer';
import registerUserReducer from '../features/registerUser/reducers/registerUserReducer';
import confirmRegistrationReducer from '../features/confirmRegistration/reducers/confirmRegistrationReducer';
import forgotPasswordReducer from '../features/forgotPassword/reducers/forgotPasswordReducer';
import verifyPasswordReducer from '../features/verifyPassword/reducers/verifyPasswordReducer';
import resendCodeReducer from '../features/resendCode/reducers/resendCodeReducer';
import changePasswordReducer from '../features/changePassword/reducers/changePasswordReducer';
import newPasswordReducer from '../features/newPassword/reducers/newPasswordReducer';
import headerTitleReducer from '../features/header/reducers';
import createClassReducer from '../features/createClass/reducers/createClassReducer';
import courseDetailReducer from '../features/courseDetail/reducers/courseDetailReducer';
import classModelsReducer from '../features/classModels/reducers/classModelsReducer';
import adminRegisterUserReducer from '../features/adminRegisterUser/reducers/adminRegisterUserReducer';
import CustomizerReducer from './customizer/CustomerSlice';
import notesReducer from './classNotes/NotesSlice';
import classNotesReducer from '../features/classNotes/reducers/classNotesReducer';
import StudentsReducer from './students/StudentsSlice';
import Gen3ClassesReducer from './gen3Classes/Gen3ClassesSlice';
import courseDetailsReducer from './courses/CourseDetailsSlice';
import courseReducer from './courses/CourseSlice';
import profileReducer from '../features/getProfile/reducers/profileReducer';
import homeworkReducer from '../features/getHomework/reducers/homeworkReducer';
import homeworkPagesReducer from '../features/getHomeworkPages/reducers/homeworkPagesReducer';
import homeworkQuestionsReducer from '../features/getHomeworkQuestions/reducers/homeworkQuestionsReducer';
import checkHomeworkQuestionsReducer from '../features/checkHomeworkQuestions/reducers/homeworkQuestionsReducer';
import { calendarReducer } from './calendar';
import { reducer as projectsBoardReducer } from './projects_board';
import { reducer as mailboxReducer } from './mailbox';
import studentsReducer from '../features/getStudents/reducers/getStudentsReducer';
import messagesReducer from '../features/getMessages/reducers/getMessagesReducer';
import sendNotificationsReducer from '../features/sendNotifications/reducers/sendNotificationsReducer';
import selectedHomeworkReducer from '../features/homeworks/reducers/homeworkReducer';
import availabilityReducer from '../features/setAvailability/reducers/setAvailabilityReducer';
import tutorsReducer from '../features/getTutorRequest/reducers/getTutorReducer';
import eventRegistrationReducer from '../features/eventRegistration/reducers/eventRegistrationReducer';
import scheduleReducer from '../features/getSchedule/reducers/getScheduleReducer';
import joinTeamReducer from '../features/getJoinTeam/reducers/getJoinTeamReducer';
import gen3RewardsReducer from '../features/gen3Rewards/reducers/gen3RewardsReducer';
import userTasksReducer from '../features/userTasks/reducers/userTasksReducer';
import manageCategoriesReducer from '../features/manageCategories/reducers/manageCategoriesReducer';
import manageCourseReducer from '../features/manageCourse/reducers/manageCourseReducer';
import bringCourseToYourLocationReducer from '../features/bringCourseToYourLocation/reducers/bringCourseToYourLocationReducer';
import manageEventsReducer from '../features/manageEvents/reducers/manageEventsReducer';
import followTeacherReducer from '../features/followTeacher/reducers/followTeacherReducer';
import courseNotificationReducer from '../features/courseNotification/reducers/courseNotificationReducer';
import makePaymentReducer from '../features/makePayment/reducers/makePaymentReducer';
import getClassesReducer from '../features/getClasses/reducers/getClassesReducer';
import getEventsReducer from '../features/getEvents/reducers/getEventsReducer';
import manageResourcesReducer from '../features/manageResources/reducers/manageResourcesReducer';
import getResourcesReducer from '../features/getResources/reducers/getResourcesReducer';
import getLocationsReducer from '../features/getLocations/reducers/getLocationsReducer';
import manageLocationsReducer from '../features/manageLocations/reducers/manageLocationsReducer';
import requestHomeworkReducer from '../features/requestHomework/reducers/requestHomeworkReducer';
import getCourseLevelsReducer from '../features/getCourseLevels/reducers/getCourseLevelsReducer';
import bookingsReducer from '../features/bookings/reducers/bookingsReducer';
import enrollClassReducer from '../features/enrollClass/reducers/enrollClassReducer';
import homePageDataReducer from '../features/getHomePageData/reducers/getHomePageDataReducer';
import getClassStudentsReducer from '../features/getClassStudents/reducers/getClassStudentsReducer';
import manageToDoReducer from '../features/manageToDo/reducers/manageToDoReducer';
import markClassAttendanceReducer from '../features/markClassAttendance/reducers/markClassAttendanceReducer';
import careersReducer from '../features/careers/reducers/careersReducer';
import careersApplyReducer from '../features/careersApply/reducers/careersApplyReducer';

export const rootReducer = combineReducers({
  login: loginReducer,
  Gen3Learning: Gen3LearningReducer,
  courseListing: courseListingReducer,
  userMetrics: userMetricsReducer,
  registerUser: registerUserReducer,
  confirmRegistration: confirmRegistrationReducer,
  forgotPassword: forgotPasswordReducer,
  verifyPassword: verifyPasswordReducer,
  resendCode: resendCodeReducer,
  changePassword: changePasswordReducer,
  newPassword: newPasswordReducer,
  header: headerTitleReducer,
  createClass: createClassReducer,
  courseDetail: courseDetailReducer,
  classModel: classModelsReducer,
  customizer: CustomizerReducer,
  notesReducer: notesReducer,
  classNotesReducer: classNotesReducer,
  StudentsReducer: StudentsReducer,
  Gen3ClassesReducer: Gen3ClassesReducer,
  newcourseDetailsReducer: courseDetailsReducer,
  newcourseReducer: courseReducer,
  adminRegisterUserReducer: adminRegisterUserReducer,
  profileReducer: profileReducer,
  homeworkReducer: homeworkReducer,
  homeworkPagesReducer: homeworkPagesReducer,
  homeworkQuestionsReducer: homeworkQuestionsReducer,
  checkHomeworkQuestionsReducer: checkHomeworkQuestionsReducer,
  calendarReducer: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  studentsReducer: studentsReducer,
  messagesReducer: messagesReducer,
  sendNotificationsReducer: sendNotificationsReducer,
  selectedHomeworkReducer: selectedHomeworkReducer,
  availabilityReducer: availabilityReducer,
  tutorsReducer: tutorsReducer,
  scheduleReducer: scheduleReducer,
  joinTeamReducer: joinTeamReducer,
  gen3RewardsReducer: gen3RewardsReducer,
  userTasksReducer: userTasksReducer,
  categories: manageCategoriesReducer,
  courses: manageCourseReducer,
  eventRegistrationReducer: eventRegistrationReducer,
  bringCourseToYourLocationReducer: bringCourseToYourLocationReducer,
  events: manageEventsReducer,
  followTeacherReducer: followTeacherReducer,
  courseNotificationReducer: courseNotificationReducer,
  makePaymentReducer: makePaymentReducer,
  ClassesReducer: getClassesReducer,
  EventsReducer: getEventsReducer,
  getResourcesReducer: getResourcesReducer,
  manageResourcesReducer: manageResourcesReducer,
  manageLocationsReducer: manageLocationsReducer,
  getLocationsReducer: getLocationsReducer,
  requestHomeworkReducer: requestHomeworkReducer,
  getCourseLevelsReducer: getCourseLevelsReducer,
  bookLocationReducer: bookingsReducer,
  enrollClassReducer: enrollClassReducer,
  homePageDataReducer: homePageDataReducer,
  getClassStudentsReducer: getClassStudentsReducer,
  manageToDoReducer: manageToDoReducer,
  markClassAttendanceReducer: markClassAttendanceReducer,
  careersReducer: careersReducer,
  careersApplyReducer: careersApplyReducer
});

export default rootReducer;

// Define and export the RootState type
export type RootState = ReturnType<typeof rootReducer>;
