const weekdayOrder = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function sortSchedules(schedules: any[]): any[] {
  return [...schedules]
    .sort((a, b) => {
      // First, sort by weekday
      const dayDiff =
        weekdayOrder.indexOf(a.schedule_data.day) -
        weekdayOrder.indexOf(b.schedule_data.day);
      if (dayDiff !== 0) return dayDiff;

      // If same day, sort by start time
      const aTime = new Date(`1970-01-01T${a.schedule_data.startTime}`);
      const bTime = new Date(`1970-01-01T${b.schedule_data.startTime}`);
      return aTime.getTime() - bTime.getTime();
    })
    .map((schedule) => ({
      ...schedule,
      schedule_data: {
        ...schedule.schedule_data,
        startTime: new Date(
          `1970-01-01T${schedule.schedule_data.startTime}`
        ).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }),
        endTime: new Date(
          `1970-01-01T${schedule.schedule_data.endTime}`
        ).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }),
      },
    }));
}
