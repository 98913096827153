import { BookingsAction, BookingsActionTypes } from '../actions/bookingsActionTypes';

interface BookingsState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: BookingsState = {
  data: [],
  loading: false,
  error: null
};

const bookingsReducer = (state = initialState, action: BookingsAction): BookingsState => {
  switch (action.type) {
    case BookingsActionTypes.BOOKINGS:
      return { ...state, loading: true, data: action.payload };
    case BookingsActionTypes.BOOKINGS_SUCCESS:
      return { ...state, loading: false, error: null,data: action.payload };
    case BookingsActionTypes.BOOKINGS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case BookingsActionTypes.RESET_BOOKINGS:
      return { ...state, data: [] };
    default:
      return state;
  }
};

export default bookingsReducer;
