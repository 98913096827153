export enum CareersApplyActionTypes {
  CAREERS_APPLY_REQUEST = 'careers/CAREERS_APPLY_REQUEST',
  CAREERS_APPLY_REQUEST_SUCCESS = 'careers/CAREERS_APPLY_REQUEST_SUCCESS',
  CAREERS_APPLY_REQUEST_ERROR = 'careers/CAREERS_APPLY_REQUEST_ERROR',
  CAREERS_APPLY_UPDATE_REQUEST = 'careers/CAREERS_APPLY_UPDATE_REQUEST',
  CAREERS_APPLY_UPDATE_REQUEST_SUCCESS = 'careers/CAREERS_APPLY_UPDATE_REQUEST_SUCCESS',
  CAREERS_APPLY_UPDATE_REQUEST_ERROR = 'careers/CAREERS_APPLY_UPDATE_REQUEST_ERROR',
  CAREERS_APPLY_DELETE_REQUEST = 'careers/CAREERS_APPLY_DELETE_REQUEST',
  CAREERS_APPLY_DELETE_REQUEST_SUCCESS = 'careers/CAREERS_APPLY_DELETE_REQUEST_SUCCESS',
  CAREERS_APPLY_DELETE_REQUEST_ERROR = 'careers/CAREERS_APPLY_DELETE_REQUEST_ERROR',
  CAREERS_APPLY_MESSAGE_REQUEST = 'careers/CAREERS_APPLY_MESSAGE_REQUEST',
  CAREERS_APPLY_MESSAGE_REQUEST_SUCCESS = 'careers/CAREERS_APPLY_MESSAGE_REQUEST_SUCCESS',
  CAREERS_APPLY_MESSAGE_REQUEST_ERROR = 'careers/CAREERS_APPLY_MESSAGE_REQUEST_ERROR'
}

export interface CareersApplyAction {
  type: CareersApplyActionTypes;
  payload?: any;
}
