import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  getAvailabilityRequest,
  setAvailabilityRequest,
  updateAvailabilityRequest,
  deleteAvailabilityRequest
} from '../../../features/setAvailability/actions/setAvailabilityActions';
import { AppDispatch, AppState } from '../../../store/configureStore';
import ScheduleBar from './ScheduleBar';
import ConfirmationModal from '../../Admin/Components/ConfirmationModal';
import { sortSchedules } from '../../../Utils/weekDaySort';
import { checkAvailabilityOverlap, format12to24 } from '../../../Utils/checkClassOverlap';

const MySchedules = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [schedules, setSchedules]: any = useState([]);
  const sortedSchedules = useMemo(() => sortSchedules(schedules), [schedules]);
  const user_api = useSelector((state: AppState) => state.login.account.userAPI);
  const idToken = useSelector((state: AppState) => state.login.user.idToken);
  const user_id = useSelector((state: AppState) => state.login.user.sub);
  const availabilityData = useSelector((state: AppState) => state.availabilityReducer.availability_data);
  const availabilityLoaded = useSelector((state: AppState) => state.availabilityReducer.loaded_availability);
  const user_schedule: any = useSelector((state: AppState) => state?.scheduleReducer?.scheduleData);
  // const schedules = useSelector((state: AppState) => state.availability.schedules);
  const [editingId, setEditingId] = useState(null);
  const [editedSchedule, setEditedSchedule] = useState({ day: '', startTime: '', endTime: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availabilityIdToDelete, setAvailabilityIdToDelete] = useState<number | null>(null);
  const [addOverlapError, setaddOverlapError] = useState<string>('');

  useEffect(() => {
    const availability_header_payload: any = {
      user_api,
      user_id: user_id,
      idToken,
      tutor_id: user_id,
      class_id: '',
      user_type: 'teachers'
    };
    dispatch(getAvailabilityRequest(availability_header_payload));
  }, [dispatch]);

  useEffect(() => {
    if (availabilityLoaded && availabilityData?.items?.length > 0) {
      const scheduleDataParsed = JSON.parse(availabilityData.items);
      setSchedules(scheduleDataParsed || []);
    }
  }, [availabilityData, availabilityLoaded]);

  const [tz, setTz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const handleAddSchedule = (newSchedule) => {
    const availability_header_payload = {
      action: 'Add',
      user_api,
      idToken,
      user_id: user_id,
      user_type: 'teachers'
    };
    let isOverlap = checkAvailabilityOverlap(
      {
        day: newSchedule?.day,
        startTime: format12to24(newSchedule?.startTime),
        endTime: format12to24(newSchedule?.endTime)
      },
      sortedSchedules.map((item) => {
        return {
          day: item?.schedule_data?.day,
          start_time: format12to24(item?.schedule_data?.startTime),
          end_time: format12to24(item?.schedule_data?.endTime)
        };
      })
    );
    if (isOverlap) {
      return 'Provided slot overlaps with current availability slots';
    } else {
      setaddOverlapError('');
      dispatch(setAvailabilityRequest(availability_header_payload, newSchedule, tz));
      setSchedules([...schedules, { availability_id: Date.now(), schedule_data: newSchedule }]);
      return '';
    }
  };

  const handleUpdateSchedule = (updatedSchedule) => {
    const availability_header_payload = {
      action: 'Update',
      user_api,
      idToken,
      user_id: user_id,
      user_type: 'teachers'
    };
    dispatch(updateAvailabilityRequest({ availability_header_payload, scheduleData: updatedSchedule }));
    setSchedules(
      schedules.map((schedule) =>
        schedule.availability_id === updatedSchedule.availability_id
          ? { ...schedule, schedule_data: updatedSchedule.schedule_data }
          : schedule
      )
    );
  };

  const handleDeleteSchedule = (availability_id) => {
    setAvailabilityIdToDelete(availability_id);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const availability_header_payload = {
      action: 'Delete',
      user_api,
      idToken,
      user_id: user_id,
      user_type: 'teachers'
    };
    dispatch(
      deleteAvailabilityRequest({
        availability_header_payload,
        scheduleData: { availability_id: availabilityIdToDelete }
      })
    );
    setSchedules(schedules.filter((schedule) => schedule.availability_id !== availabilityIdToDelete));
    setIsModalOpen(false);
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
  };

  const handleEditClick = (schedule) => {
    setEditingId(schedule.availability_id);
    setEditedSchedule({
      ...schedule.schedule_data,
      startTime: format12to24(schedule.schedule_data?.startTime),
      endTime: format12to24(schedule.schedule_data?.endTime)
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditedSchedule({ day: '', startTime: '', endTime: '' });
  };

  const handleSaveEdit = (id) => {
    console.log({
      day: editedSchedule?.day,
      startTime: format12to24(editedSchedule?.startTime),
      endTime: format12to24(editedSchedule?.endTime)
    });
    const updatedSchedule = {
      availability_id: id,
      schedule_data: {
        day: editedSchedule?.day,
        startTime: editedSchedule?.startTime,
        endTime: editedSchedule?.endTime
      }
    };
    handleUpdateSchedule(updatedSchedule);
    setEditingId(null);
    setEditedSchedule({ day: '', startTime: '', endTime: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedSchedule((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Box>
      <ScheduleBar
        onAddSchedule={handleAddSchedule}
        addOverlapError={addOverlapError}
        setaddOverlapError={setaddOverlapError}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules && schedules.length > 0 ? (

                sortedSchedules?.map((schedule: any) => {
                  let isOverlap = checkAvailabilityOverlap(
                    {
                      day: schedule.schedule_data?.day,
                      startTime: format12to24(schedule.schedule_data?.startTime),
                      endTime: format12to24(schedule.schedule_data?.endTime)
                    },
                    user_schedule?.user_schedule
                  );
                  return (
                    <TableRow key={schedule.availability_id}>
                      {editingId === schedule.availability_id ? (
                        <React.Fragment>
                          <TableCell>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Day</InputLabel>
                              <Select name="day" value={editedSchedule.day} onChange={handleInputChange} label="Day">
                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
                                  (day) => (
                                    <MenuItem key={day} value={day}>
                                      {day}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="time"
                              name="startTime"
                              label="Start Time"
                              variant="outlined"
                              fullWidth
                              disabled={isOverlap}
                              value={editedSchedule?.startTime}
                              onChange={handleInputChange}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="time"
                              name="endTime"
                              label="End Time"
                              variant="outlined"
                              fullWidth
                              disabled={isOverlap}
                              value={editedSchedule?.endTime}
                              onChange={handleInputChange}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleSaveEdit(schedule.availability_id)} color="primary">
                              <SaveIcon />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit} color="secondary">
                              <CancelIcon />
                            </IconButton>
                          </TableCell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell>{schedule.schedule_data.day}</TableCell>
                          <TableCell>{schedule.schedule_data.startTime}</TableCell>
                          <TableCell>{schedule.schedule_data.endTime}</TableCell>
                          <TableCell>
                            <IconButton disabled={isOverlap} onClick={() => handleEditClick(schedule)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              disabled={isOverlap}
                              onClick={() => handleDeleteSchedule(schedule.availability_id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No schedules available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        message="Are you sure you want to delete this availability?"
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        open={isModalOpen}
      />
    </Box>
  );
};

export default MySchedules;
