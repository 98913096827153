import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Paper,
  Table,
  TableHead,
  List,
  ListItem,
  IconButton,
  CircularProgress,
  MenuItem,
  Modal,
  Dialog,
  DialogTitle
} from '@mui/material';
import {
  Add as AddIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsRequest } from '../../../../features/getLocations/actions/getLocationsActions';
import { AppDispatch, AppState } from '../../../../store/configureStore';
import { getHomePageDataRequest } from '../../../../features/getHomePageData/actions/getHomePageDataActions';
import ImageUpload from '../../../../Components/ImageUpload';
import { uploadObject } from '../../../../Utils/uploadObject';
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import { updateProfileRequest } from '../../../../features/getProfile/actions/profileActions';
import { GetImageURL } from '../../../../Components/RenderImage';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { PureLightTheme } from '../../../../defaultTheme/schemes/PureLightTheme';
import ManageCourse from '../../Courses/ManageCourses';
import { getCourseRequest } from '../../../../features/manageCourse/actions/manageCourseActions';
import { updateLocationsRequest } from '../../../../features/manageLocations/actions/manageLocationsActions';
import { useSnackbar } from 'notistack';
import NewTutorSignCourses from '../../Courses/NewTutorSignUpCourses';
import MySchedules from '../../../Schedule/components/MySchedules';
import DialogTitleWithClose from '../../../../Components/DialogTitleWithClose/DialogTitleWithClose';

const initialLocationState = {
  location_id: null,
  location_name: '',
  country: '',
  city: '',
  state: '',
  address: '',
  size: '',
  locationImageUrl: '',
  amenities: '',
  cost: '',
  costFactor: 'hourly',
  timings: '',
  contact_details: {
    personname: '',
    phone: '',
    email: ''
  }
};

const NewTutorSignup = () => {
  const theme = PureLightTheme;
  const dispatch: AppDispatch = useDispatch();
  const [tutorInfo, setTutorInfo] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    profile_avatar: '',
    profile_avatar_url: '',
    languages: []
  });
  const [activeStep, setActiveStep] = useState(0);
  const [locations, setLocations] = useState<any>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [apiData, setAPIData] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProgram, setSelectedProgram] = useState<string | null>(null);
  const [selectedPrograms, setSelectedPrograms] = useState<any[]>([]);
  const [customSubject, setCustomSubject] = useState('');
  const [tutoringPrices, setTutoringPrices] = useState([
    { id: 'one-on-one', fee: 30 },
    { id: 'group-tutoring', fee: 20 },
    { id: 'on-demand', fee: 30 }
  ]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [specialty, setSpecialty] = useState('');
  const [specialties, setSpecialties] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [newLocation, setNewLocation] = useState(initialLocationState);
  const [languages, setLanguages] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [addLocation, setaddLocation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const course_list_status: any = useSelector((state: AppState) => state.courses.creating);
  const course_list_status_getting: any = useSelector((state: AppState) => state.courses.getting);
  const coursedeleting: any = useSelector((state: AppState) => state.courses.updating);
  const location_list = useSelector((state: AppState) => state.getLocationsReducer.data);
  const user_api: any = useSelector((state: AppState) => state.login.account.userAPI);
  const idToken: any = useSelector((state: AppState) => state.login.user.idToken);
  const successfullyUpdated = useSelector((state: AppState) => state?.profileReducer?.update_profile_loading);
  const course_list: any = useSelector((state: AppState) => state.courses.data || []);
  const location_list_updating = useSelector((state: AppState) => state.manageLocationsReducer.updating);
  const location_list_data = useSelector((state: AppState) => state.manageLocationsReducer.data);
  const user_id = localStorage.getItem('userId');
  const homePageData = useSelector((state: AppState) => state.homePageDataReducer.data);

  const navigate = useNavigate();
  const profile_image_url: any = GetImageURL({
    object_name: tutorInfo.profile_avatar,
    presigned: false,
    folder: 'public/profile'
  });

  useEffect(() => {
    dispatch(getLocationsRequest({ action: 'get' }));
  }, [dispatch]);

  useEffect(() => {
    if (location_list_data) {
      dispatch(getLocationsRequest({ action: 'get' }));
    }
  }, [location_list_updating, location_list_data]);

  useEffect(() => {
    if (coursedeleting && course_list) {
      dispatch(
        getCourseRequest({
          action: 'get',
          parent_category: 'program_category',
          idToken: idToken,
          user_api: user_api
        })
      );
    }
  }, [coursedeleting]);

  useEffect(() => {
    if (course_list_status && course_list) {
      dispatch(
        getCourseRequest({
          action: 'get',
          parent_category: 'program_category',
          idToken: idToken,
          user_api: user_api
        })
      );
    }
  }, [course_list_status, course_list]);

  useEffect(() => {
    if (successfullyUpdated) {
      setLoading(false);
      setSuccessMessage(true);
      navigate('/dashboard');
    }
  }, [successfullyUpdated]);

  useEffect(() => {
    dispatch(getHomePageDataRequest({}));
  }, [dispatch]);

  useEffect(() => {
    setLocations(location_list);

    // const getAPIData = async () => {
    //   try {
    //     const result = await apiService.gen3HomePage();
    //     console.log('programs');
    //     setAPIData(result?.programs);
    //     localStorage.setItem('landingPageData', JSON.stringify(result));
    //   } catch (error) {
    //     setError('Error loading data');
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    setAPIData(homePageData);
    // getAPIData();
  }, [location_list, homePageData]);

  useEffect(() => {
    setTutorInfo((prev) => ({
      ...prev,
      profile_avatar_url: profile_image_url
    }));
  }, [profile_image_url]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTutorInfo({ ...tutorInfo, [name]: value });
  };

  const handleSelect = (location: any) => {
    setSelectedLocations((prev) =>
      prev.some((loc) => loc.location_id === location.location_id)
        ? prev.filter((loc) => loc.location_id !== location.location_id)
        : [...prev, location]
    );
  };

  const handleProgramSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProgram(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const user_data = {
      name: tutorInfo.name,
      email_address: {
        primary: tutorInfo.email,
        secondary: []
      },
      new_user: false,
      message: selectedPrograms,
      phone_number: tutorInfo.phone,
      shortDesc: tutorInfo.description,
      profile_avatar: tutorInfo.profile_avatar,
      image_avatar: tutorInfo.profile_avatar_url,
      specialty: specialties,
      languages: languages,
      city: '',
      state: 'Your State Here',
      tutoring_locations: selectedLocations.map((location) => `${location.location_name} - ${location.address}`),
      tutoring_price: tutoringPrices.map((price) => ({
        class_type: price.id,
        cost: price.fee.toString()
      })),
      social_media_links: {
        facebook_link: '',
        instagram_link: '',
        linkedin_link: '',
        twitter_link: ''
      },
      description: tutorInfo.description,
      total_students: 0
    };

    try {
      dispatch(
        updateProfileRequest({
          id: user_id,
          user_id: user_id,
          new_user: false,
          user_api: user_api,
          status: 'active',
          userId: user_id?.split('|')[0],
          idToken: idToken,
          groups: 'teachers',
          user_data: user_data
        })
      );
    } catch (err) {
      console.error('Error during submission:', err);
    }
  };

  const validateForm = () => {
    const requiredFields = ['name', 'email', 'phone', 'description'];
    for (let field of requiredFields) {
      if (!tutorInfo[field as keyof typeof tutorInfo]) {
        alert(`${field} is required`);
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };
  const handleProfileImageChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageType = 'profile';
      const uploadedImageUrl = await uploadObject({
        file: selectedFile,
        image_type: imageType,
        upload: true
      });
      setTutorInfo((prev) => ({
        ...prev,
        profile_avatar: selectedFile.name,
        profile_avatar_url: uploadedImageUrl
      }));
    }
  };

  const handleClassOfferingChange = (e, index) => {
    const updatedPrices = [...tutoringPrices];
    updatedPrices[index].id = e.target.value;
    setTutoringPrices(updatedPrices);
  };

  const handlePriceChange = (e, index) => {
    const updatedPrices = [...tutoringPrices];
    updatedPrices[index].fee = e.target.value;
    setTutoringPrices(updatedPrices);
  };

  const addLanguage = (e) => {
    setLanguages([...languages, selectedLanguage]);
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleAddClassOffering = () => {
    const newOffering: any = {
      id: '',
      fee: ''
    };

    setTutoringPrices((prevPrices: any) => [...prevPrices, newOffering]);
  };
  const handleAddSpecialty = () => {
    if (specialty.trim() !== '') {
      setSpecialties((prevSpecialties) => [...prevSpecialties, specialty]);
      setSpecialty('');
    }
  };

  const handleRemoveSpecialty = (index: number) => {
    setSpecialties((prevSpecialties) => prevSpecialties.filter((_, i) => i !== index));
  };

  const removeLanguage = (index: number) => {
    setLanguages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddLocation = (event: React.FormEvent) => {
    event.preventDefault();
    const payload = {
      action: isEditing ? 'update' : 'add',
      user_api: user_api,
      idToken: idToken,
      ...newLocation,
      user_id: user_id,
      location_id: isEditing && newLocation ? newLocation.location_id : null
    };
    dispatch(updateLocationsRequest(payload));
    setaddLocation(false);
    enqueueSnackbar('Location Updated ', { variant: 'success' });
  };

  const handleEditLocation = (location) => {
    console.log(location);
    setNewLocation(location);
    setIsEditing(true);
    setaddLocation(true);
  };

  const handleSelectPrograms = (event: any) => {
    console.log('selected program');
    console.log(event)
    let findProgram = selectedPrograms?.find((program) => program?.id === event?.course_id);
    if (findProgram) {
      let updatedPrograms = selectedPrograms?.filter((program) => program?.id !== event?.course_id);
      setSelectedPrograms([...updatedPrograms]);
    }
    else {
      setSelectedPrograms([...selectedPrograms, {
        id: event?.course_id,
        course_name: event?.course_name,
        course_category: event?.course_category
      }]);
    }
  }

  const steps = ['User Information', 'Tutoring Locations', 'Subjects', 'Pricing', 'Specialty', 'Languages', 'Availability'];

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ mt: 4, p: 3, borderRadius: '20px', backgroundColor: '#fafafa', boxShadow: 3 }}
    >
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                sx: {
                  color: activeStep === index ? '#4caf50' : '#ccc',
                  '&.Mui-completed': { color: theme.palette.primary.main }
                }
              }}
              sx={{
                '& .MuiStepLabel-label': {
                  color: activeStep === index ? '#4caf50' : '#555',
                  fontWeight: activeStep === index ? 'bold' : 'normal'
                },
                '& .Mui-completed .MuiStepLabel-label': { color: theme.palette.primary.main, fontWeight: 600 }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box component={Paper} elevation={3} sx={{ mt: 3, p: 3, borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            Tutor Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label="Full Name"
                value={tutorInfo.name}
                onChange={handleChange}
                fullWidth
                required
                helperText="Please enter your full name"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                label="Email Address"
                type="email"
                value={tutorInfo.email}
                onChange={handleChange}
                fullWidth
                required
                helperText="Please enter a valid email address"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="phone"
                label="Phone Number"
                type="tel"
                value={tutorInfo.phone}
                onChange={handleChange}
                fullWidth
                required
                helperText="Please enter your phone number"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                value={tutorInfo.description}
                onChange={handleChange}
                fullWidth
                required
                multiline
                rows={4}
                helperText="Provide a brief description of yourself"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" alignItems="center">
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Upload Profile Picture
                </Typography>
                <ImageUpload
                  imageType="profile"
                  user_data={tutorInfo}
                  user_id="user_id"
                  user_api="user_api"
                  idToken="id_token"
                  refreshKeySetter={setActiveStep}
                  onImageChange={handleProfileImageChange}
                />
                {tutorInfo?.profile_avatar !== '' && (
                  <Box sx={{ mt: 2 }}>
                    <img
                      src={tutorInfo.profile_avatar_url}
                      alt={tutorInfo.profile_avatar_url}
                      style={{ width: '150px', height: '150px' }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {activeStep === 1 && (
        <React.Fragment>
          <Button type="button" variant="contained" color="primary" fullWidth onClick={() => setaddLocation(true)}>
            Add Location
          </Button>

          <Box component={Paper} elevation={3} sx={{ mt: 3, p: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
              Select Tutoring Locations
            </Typography>
            <Dialog open={addLocation} onClose={() => setaddLocation(false)}>

              <DialogTitleWithClose title={isEditing ? 'Update Location' : 'Add New Location'} handleClose={() => setaddLocation(false)} />
              <Box component="form" sx={{ p: 4 }} onSubmit={handleAddLocation}>
                {/* Form Fields */}
                <TextField
                  name="location_name"
                  label="Location Name"
                  value={newLocation.location_name}
                  onChange={(e) => setNewLocation({ ...newLocation, location_name: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="country"
                  label="Country"
                  value={newLocation.country}
                  onChange={(e) => setNewLocation({ ...newLocation, country: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="city"
                  label="City"
                  value={newLocation.city}
                  onChange={(e) => setNewLocation({ ...newLocation, city: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="state"
                  label="State"
                  value={newLocation.state}
                  onChange={(e) => setNewLocation({ ...newLocation, state: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="address"
                  label="Address"
                  value={newLocation.address}
                  onChange={(e) => setNewLocation({ ...newLocation, address: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="size"
                  label="Size of the Room (sq. ft.)"
                  value={newLocation.size}
                  onChange={(e) => setNewLocation({ ...newLocation, size: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="locationImageUrl"
                  label="Location Image URL"
                  value={newLocation.locationImageUrl}
                  onChange={(e) => setNewLocation({ ...newLocation, locationImageUrl: e.target.value })}
                  fullWidth
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="amenities"
                  label="Amenities"
                  value={newLocation.amenities}
                  onChange={(e) => setNewLocation({ ...newLocation, amenities: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="cost"
                  label="Cost"
                  value={newLocation.cost}
                  onChange={(e) => setNewLocation({ ...newLocation, cost: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="timings"
                  label="Timings"
                  value={newLocation.timings}
                  onChange={(e) => setNewLocation({ ...newLocation, timings: e.target.value })}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="personname"
                  label="Contact Person Name"
                  value={newLocation.contact_details.personname}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_details: { ...newLocation.contact_details, personname: e.target.value }
                    })
                  }
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="phone"
                  label="Contact Person Phone"
                  value={newLocation.contact_details.phone}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_details: { ...newLocation.contact_details, phone: e.target.value }
                    })
                  }
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="email"
                  label="Contact Person Email"
                  value={newLocation.contact_details.email}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_details: { ...newLocation.contact_details, email: e.target.value }
                    })
                  }
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputLabelProps={{ shrink: true }}
                />

                {/* Other fields as shown in the original code */}
                <Button type="button" variant="contained" color="primary" fullWidth onClick={handleAddLocation}>
                  {isEditing ? 'Update Location' : 'Add Location'}
                </Button>
              </Box>
            </Dialog>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Location Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Actions</TableCell> {/* Added actions column */}
                </TableRow>
              </TableHead>
              <TableBody>
                {locations &&
                  locations?.location_list?.map((location) => (
                    <TableRow
                      key={location.location_id}
                      sx={{
                        '&:hover': { backgroundColor: '#f5f5f5' },
                        backgroundColor: selectedLocations.some((loc) => loc.location_id === location.location_id)
                          ? '#e0f7fa'
                          : 'inherit'
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedLocations.some((loc) => loc.location_id === location.location_id)}
                          onChange={() => handleSelect(location)}
                          sx={{
                            color: selectedLocations.some((loc) => loc.location_id === location.location_id)
                              ? '#00796b'
                              : 'default'
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {location.location_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{location.address}</Typography>
                      </TableCell>
                      <TableCell>
                        {location?.created_by === user_id ? (
                          <Button onClick={() => handleEditLocation(location)} variant="outlined" color="primary">
                            Edit
                          </Button>
                        ) : (
                          <p>No Permission</p>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </React.Fragment>
      )}

      {activeStep === 2 && (
        <Box sx={{ mt: 4, p: 3, borderRadius: '12px', backgroundColor: '#f9f9f9', boxShadow: 1 }}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            </Box>
          </Grid>
          <NewTutorSignCourses selectedPrograms={selectedPrograms} handleSelectPrograms={handleSelectPrograms} />
        </Box>
      )}

      {activeStep === 3 && (
        <Box component={Paper} elevation={3} sx={{ mt: 3, p: 2, borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            Pricing Information
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Class Offering</TableCell>
                <TableCell>Fee per hour ($)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tutoringPrices.map((price, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField value={price.id} onChange={(e) => handleClassOfferingChange(e, index)} fullWidth />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={price.fee}
                      onChange={(e) => handlePriceChange(e, index)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleAddClassOffering}>
            Add Class Offering
          </Button>

          <Box sx={{ mt: 4 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please update the class offerings and fees as needed.
            </Typography>
          </Box>
        </Box>
      )}

      {activeStep === 4 && (
        <Box component={Paper} elevation={3} sx={{ mt: 3, p: 3, borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            Specialty
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                name="specialty"
                label="Specialty"
                value={specialty}
                onChange={(e) => setSpecialty(e.target.value)}
                fullWidth
                helperText="Add a new specialty"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" onClick={handleAddSpecialty} fullWidth>
                Add Specialty
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Specialty List</Typography>
            <List>
              {specialties?.map((specialty, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#f0f0f0',
                    borderRadius: 1,
                    mb: 2
                  }}
                >
                  <Typography>{specialty}</Typography>
                  <IconButton color="secondary" onClick={() => handleRemoveSpecialty(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {activeStep === 5 && (
        <Box component={Paper} elevation={3} sx={{ mt: 3, p: 3, borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            Languages
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="language"
                label="Language"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                fullWidth
                required
                helperText="Please enter the languages you speak"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="primary" onClick={addLanguage} sx={{ borderRadius: 8 }} fullWidth>
                Add Language
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Languages
              </Typography>
              <List sx={{ backgroundColor: '#f0f0f0', borderRadius: 2, p: 2 }}>
                {languages?.length > 0 ? (
                  languages?.map((language, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                        p: 1,
                        backgroundColor: theme.palette.common.white,
                        borderRadius: 1,
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                        alignItems: 'center'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LanguageIcon sx={{ color: '#4caf50', mr: 1 }} />
                        <Typography variant="body1">{language}</Typography>
                      </Box>
                      <IconButton color="secondary" onClick={() => removeLanguage(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography sx={{ textAlign: 'center', color: '#757575' }}>No languages added yet</Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </Box>
      )}

      {activeStep === 6 && (
        <MySchedules />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack} variant="outlined" color="secondary">
            Back
          </Button>
        )}
        {successfullyUpdated ? (
          <CircularProgress size={24} />
        ) : (
          <LoadingButton
            loading={loading}
            onClick={activeStep < steps.length - 1 ? handleNext : () => setConfirmationModal(true)}
            variant="contained"
            loadingIndicator={<CircularProgress size={24} />}
            color="primary"
          >
            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          </LoadingButton>
        )}
      </Box>

      <Dialog open={confirmationModal} onClose={() => setaddLocation(false)}>
        <DialogTitleWithClose title="Are you sure you want to submit?" handleClose={() => setConfirmationModal(false)} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Button variant="outlined" color="secondary" onClick={() => setConfirmationModal(false)}>
            No
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Yes
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default NewTutorSignup;
