import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiService } from '../../../services/api';

interface ManageResourcesPayload {
  action: string;
  data: any;
  user_api: string;
  idToken: string;
}

export const manageResourcesRequest = createAsyncThunk<any, ManageResourcesPayload>(
  'manageResources/MANAGE_RESOURCES',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await apiService.manageResources(payload);
      if (!response.resource_id) {
        throw new Error('No data received from the server');
      }
      return response.resource_id;
    } catch (error: any) {
      console.error('manageResourcesRequest error:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);



