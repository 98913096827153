export enum GetCourseLevelsActionTypes {
  GET_COURSE_LEVELS = 'getCourseLevels/GET_COURSE_LEVELS',
  GET_COURSE_LEVELS_SUCCESS = 'getCourseLevels/GET_COURSE_LEVELS_SUCCESS',
  GET_COURSE_LEVELS_ERROR = 'getCourseLevels/GET_COURSE_LEVELS_ERROR'
}

export interface GetCourseLevelsAction {
  type: GetCourseLevelsActionTypes;
  payload?: any;
}
