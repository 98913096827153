import GlobalStyles from './globalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProviderWrapper from './defaultTheme/ThemeProvider';
import Router from './Components/router';
import { useRoutes } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from './context/LocalizationContext';
import { useDispatch } from 'react-redux';
import { logoutThunk } from './features/otherReducers/Gen3LearningActions';
import { AppDispatch } from './store/configureStore';
import { useEffect } from 'react';
import { requestPermission } from './firebase';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import './Components/i18n';

function App() {
  const routing = useRoutes(Router);

  useEffect(() => {
    requestPermission();
  }, []);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration, 10);
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime > expirationTime) {
        dispatch(logoutThunk());
        localStorage.clear();
        window.location.assign('/home');
      }
    }
  };

  return (
    <HelmetProvider>
      <ThemeProviderWrapper>
        <LocalizationProvider>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <MeetingProvider>
              <CssBaseline />
              {GlobalStyles}
              <ScrollToTop>{routing}</ScrollToTop>
            </MeetingProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProviderWrapper>
    </HelmetProvider>
  );
}

export default App;
