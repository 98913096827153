import { MarkClassAttendanceAction, MarkClassAttendanceActionTypes } from '../actions/markClassAttendanceActionTypes';

interface MarkClassAttendanceState {
  // TODO: Add Type
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: MarkClassAttendanceState = {
  data: [],
  loading: false,
  error: null
};

const markClassAttendanceReducer = (
  state = initialState,
  action: MarkClassAttendanceAction
): MarkClassAttendanceState => {
  switch (action.type) {
    case MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST:
      return { ...state, loading: true, data: action.payload };
    case MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default markClassAttendanceReducer;
