import { getLocationsActionTypes, getLocationsAction } from '../actions/getLocationsActionTypes';

interface getLocationsState {
  data: string[];
  loading: boolean;
  load_error: string | null;
}

const initialState: getLocationsState = {
  data: [],
  loading: false,
  load_error: null
};

const getLocationsReducer = (state = initialState, action: getLocationsAction): getLocationsState => {
  switch (action.type) {
    case getLocationsActionTypes.GET_LOCATIONS:
      return { ...state, loading: true };
    case getLocationsActionTypes.GET_LOCATIONS_SUCCESS:
      return { ...state, data: action.payload, loading: false, load_error: null };
    case getLocationsActionTypes.GET_LOCATIONS_ERROR:
      return { ...state, loading: false, load_error: action.payload };
    default:
      return state;
  }
};

export default getLocationsReducer;
