import { useRef } from 'react';
import { Box, Button, styled } from '@mui/material';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import { useDispatch } from 'react-redux';
import { useSnackbarUtils } from '../../Utils/SnackBarNotification';
import { updateProfileRequest } from '../../features/getProfile/actions/profileActions';
import { uploadObject } from '../../Utils/uploadObject';
import { AppDispatch } from '../../store/configureStore';


const Input = styled('input')({
  display: 'none'
});

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    .MuiButton-root {
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(1, 2)};
      text-transform: none;
      box-shadow: ${theme.colors.shadows.primary};
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const ImageUpload = ({ imageType, user_data, user_id, user_api, idToken, refreshKeySetter,onImageChange }) => {
  const fileInputRef = useRef(null);

  return (
    <ButtonUploadWrapper>
      <Input accept="image/*" id={`${imageType}-file`} type="file" onChange={onImageChange} ref={fileInputRef} />
      <label htmlFor={`${imageType}-file`}>
        <Button variant="contained" component="span" startIcon={<UploadTwoToneIcon />} >
          Upload Image
        </Button>
      </label>
    </ButtonUploadWrapper>
  );
};

export default ImageUpload;
