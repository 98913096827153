import { takeLatest, put, call } from "redux-saga/effects";
import { apiService } from "../../../services/api";
import { MarkClassAttendanceActionTypes } from "../actions/markClassAttendanceActionTypes";
import {
  markClassAttendanceError,
  markClassAttendanceSuccess,
} from "../actions/markClassAttendanceActions";

function* markClassAttendanceSaga(req) {
  try {
    // Call the API using the call effect
    // TODO: FIX the API function
    const response = yield call(apiService.markClassAttendance, req.payload);
    // Dispatch the success action with the API response
    yield put(markClassAttendanceSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(markClassAttendanceError(error.message));
  }
}

export function* watchMarkClassAttendance() {
  yield takeLatest(
    MarkClassAttendanceActionTypes.MARK_ATTENDANCE_REQUEST,
    markClassAttendanceSaga
  );
}
