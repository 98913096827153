import React, { createContext, useContext, useState, ReactNode } from 'react';
import { enUS, Locale } from 'date-fns/locale';
import { format } from 'date-fns';

// Define the structure of the context
interface LocalizationContextProps {
  locale: Locale;
  setLocale: (locale: Locale) => void;
  formatDate: (date: Date) => string;
}

// Create context with default values
const LocalizationContext = createContext<LocalizationContextProps | undefined>(undefined);

// Create the provider component
export function LocalizationProvider({ children }: { children: ReactNode }) {
  const [locale, setLocale] = useState<Locale>(enUS);

  const formatDate = (date: Date) => format(date, 'P', { locale });

  return (
    <LocalizationContext.Provider value={{ locale, setLocale, formatDate }}>{children}</LocalizationContext.Provider>
  );
}

// Custom hook for easier access to the context
export function useLocalization() {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error('useLocalization must be used within a LocalizationProvider');
  }
  return context;
}




