export const primaryColor = "#125B50";
export const secondaryColor = "#FF8225";
export const tertiaryColor = "#fde74c";
export const whiteColor = "#ffffff";
export const blackColor = "#000000";
export const gen3BannerStripeColor = "#1E7F6A";
export const gen3BannerStripeTextColor = "#FFD700";
export const textColor = "#2D3748";
export const gen3AccentColor = "#fde74c";
export const gen3SectionColor = "#E2E8F0";
export const gen3CardColor = "#EEF4F8";
export const gen3AlternateCardColor = "#F7FAFC";
export const gen3SectionTextColor = "#2D3748";
export const gen3AlternateSectionColor = "#125B50";
export const gen3AlternateSectionTextColor = "#FFFFFF";
export const gen3StartGradientColor = secondaryColor;
export const gen3EndGradientColor = tertiaryColor;
export const gen3SecondaryButtonHoverColor = "#e57521";
export const gen3PrimaryButtonHoverColor = "#63B3ED";
