import { HomeworkPagesActionTypes, HomeworkPagesAction } from '../actions/HomeworkPagesActionTypes';

interface HomeworkPagesState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: HomeworkPagesState = {
  data: [],
  loading: false,
  error: null
};

const homeworkPagesReducer = (state = initialState, action: HomeworkPagesAction): HomeworkPagesState => {
  switch (action.type) {
    case HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST:
      return { ...state, loading: true };
    case HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST:
      return { ...state, loading: true };
    case HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST:
      return { ...state, loading: true };
    case HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default homeworkPagesReducer;
