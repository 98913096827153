import React from 'react';
import Gen3Logo from '../../Assets/Gen3HomeLogo.svg';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Instagram from '@mui/icons-material/Instagram';
import Facebook from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedIn from '@mui/icons-material/LinkedIn';
import YouTube from '@mui/icons-material/YouTube';
import { Box, IconButton, Stack } from '@mui/material';
import data from './../../Pages/HomePage/data/pageData.json';
import { PureLightTheme } from '../../defaultTheme/schemes/PureLightTheme';
import { whiteColor } from '../../styleVariables';
import SubscribeToNewsletter from './Subscription';

export default function FooterContainer() {
  const theme = PureLightTheme;
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        p: 3,
        mt: 5, // Changed to 'auto' to push it to the bottom of the container
        position: 'relative', // Changed back to relative
        width: '100%'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <img src={Gen3Logo} className="footer-logo" alt="logo" style={{ maxWidth: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color={theme.palette.info.main} gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2">{data.contact.address}</Typography>
            <Typography variant="body2">Email: {data.contact.email}</Typography>
            <Typography variant="body2">Phone: {data.contact.phone}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center">
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <IconButton>
                    <Typography variant="h6" color={theme.palette.info.main} sx={{ mr: 1 }}>
                      Follow Us
                    </Typography>
                  </IconButton>
                  <IconButton
                    href={data.contact.facebook_link}
                    target="_blank"
                    sx={{
                      color: whiteColor
                    }}
                  >
                    <Facebook />
                  </IconButton>
                  <IconButton
                    href={data.contact.instagram_link}
                    target="_blank"
                    sx={{
                      color: whiteColor
                    }}
                  >
                    <Instagram />
                  </IconButton>

                  <IconButton
                    href={data.contact.twitter_link}
                    target="_blank"
                    sx={{
                      color: whiteColor
                    }}
                  >
                    <XIcon />
                  </IconButton>
                  <IconButton
                    href={data.contact.linkedin_link}
                    target="_blank"
                    sx={{
                      color: whiteColor
                    }}
                  >
                    <LinkedIn />
                  </IconButton>

                  <IconButton
                    href={data.contact.youtube_link}
                    target="_blank"
                    sx={{
                      color: whiteColor
                    }}
                  >
                    <YouTube />
                  </IconButton>
                </Stack>
                <SubscribeToNewsletter />
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href={data.contact.website_link}>
              {data.contact.website}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
