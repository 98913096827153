import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { BringCourseToYourLocationActionTypes } from '../actions/bringCourseToYourLocationActionTypes';
import { bringCourseToYourLocationError,bringCourseToYourLocationSuccess } from '../actions/bringCourseToYourLocationActions';

function* bringCourseToYourLocationSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.requestCourseToLocation, req.payload);
    // Dispatch the success action with the API response
    yield put(bringCourseToYourLocationSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(bringCourseToYourLocationError(error.message));
  }
}

export function* watchBringCourseToYourLocation() {
  yield takeLatest(BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST, bringCourseToYourLocationSaga);
}
