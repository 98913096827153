import { RequestHomeworkActionTypes, RequestHomeworkAction } from './requestHomeworkActionTypes';

export const requestHomework = (data: any): RequestHomeworkAction => ({
  type: RequestHomeworkActionTypes.REQUEST_HOMEWORK,
  payload: data
});

export const requestHomeworkSuccess = (data: any): RequestHomeworkAction => ({
  type: RequestHomeworkActionTypes.REQUEST_HOMEWORK_SUCCESS,
  payload: data
});

export const requestHomeworkError = (error: string): RequestHomeworkAction => ({
  type: RequestHomeworkActionTypes.REQUEST_HOMEWORK_ERROR,
  payload: error
});
