// features/login/reducers/loginReducer.ts
import { AdminRegisterActionTypes, AdminRegisterAction } from '../actions/AdminRegisterUserActionTypes';

interface AdminRegisterUserState {
  user: string | null;
  loading: boolean;
  error: string | null;
  user_list: [] | null;
  student_list: [] | null;
  teacher_list: [] | null;
}

const initialState: AdminRegisterUserState = {
  user: null,
  loading: false,
  error: null,
  user_list: [],
  student_list: [],
  teacher_list: []
};

const adminRegisterUserReducer = (state = initialState, action: AdminRegisterAction): AdminRegisterUserState => {
  switch (action.type) {
    case AdminRegisterActionTypes.ADMIN_REGISTER_USER:
      return { ...state, loading: true };
    case AdminRegisterActionTypes.ADMIN_REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case AdminRegisterActionTypes.ADMIN_REGISTER_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AdminRegisterActionTypes.ADMIN_LIST_USER:
      return { ...state, loading: true };
    case AdminRegisterActionTypes.ADMIN_LIST_USER_SUCCESS:
      return { ...state, user_list: action.payload, loading: false, error: null };
    case AdminRegisterActionTypes.ADMIN_LIST_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AdminRegisterActionTypes.TEACHER_REGISTER_STUDENT:
      return { ...state, loading: true };
    case AdminRegisterActionTypes.TEACHER_REGISTER_STUDENT_SUCCESS:
      return { ...state, student_list: action.payload, loading: false, error: null };
    case AdminRegisterActionTypes.TEACHER_REGISTER_STUDENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default adminRegisterUserReducer;
