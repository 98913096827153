import { takeEvery, put, call, delay } from 'redux-saga/effects';
import { SendNotificationsActionTypes } from '../actions/SendNotificationsActionTypes';
import {
  sendNotificationsRequest,
  sendNotificationsRequestSuccess,
  sendNotificationsRequestError
} from '../actions/sendNotificationsActions';
import { apiService } from '../../../services/api';

function* sendNotificationsRequestSaga(req) {
  try {
    // Call the API using the call effect

    const response = yield call(apiService.sendNotifications, req.payload);

    // Dispatch the success action with the API response
    yield put(sendNotificationsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(sendNotificationsRequestError(error.message));
  }
}

function* fetchNotificationRequestSaga(req) {
  try {
    // Call the API using the call effect

    const response = yield call(apiService.sendNotifications, req.payload);

    // Dispatch the success action with the API response
    yield put(sendNotificationsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(sendNotificationsRequestError(error.message));
  }
}

export function* watchsendNotificationsRequest() {
  yield takeEvery(SendNotificationsActionTypes.SEND_NOTIFICATIONS, sendNotificationsRequestSaga);
  yield takeEvery(SendNotificationsActionTypes.FETCH_NOTIFICATIONS, fetchNotificationRequestSaga);
}
