import { takeLatest, put, call } from 'redux-saga/effects';
import { EnrollClassActionTypes } from '../actions/EnrollInClassActionTypes';
import { enrollClassRequestSuccess, enrollClassRequestError } from '../actions/enrollClassActions';
import { apiService } from '../../../services/api';

function* enrollInClassRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.enrollClass, req.payload);

    // Dispatch the success action with the API response
    yield put(enrollClassRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(enrollClassRequestError(error.message));
  }
}

export function* watchEnrollInClassRequest() {
  yield takeLatest(EnrollClassActionTypes.ENROLL_CLASS_REQUEST, enrollInClassRequestSaga);
}
