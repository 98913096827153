export enum ManageLocationsActionTypes {
  CREATE_LOCATIONS = 'manageLocations/CREATE_LOCATIONS',
  CREATE_LOCATIONS_SUCCESS = 'manageLocations/CREATE_LOCATIONS_SUCCESS',
  CREATE_LOCATIONS_ERROR = 'manageLocations/CREATE_LOCATIONS_ERROR',
  UPDATE_LOCATIONS = 'manageLocations/UPDATE_LOCATIONS',
  UPDATE_LOCATIONS_SUCCESS = 'manageLocations/UPDATE_LOCATIONS_SUCCESS',
  UPDATE_LOCATIONS_ERROR = 'manageLocations/UPDATE_LOCATIONS_ERROR'
}

export interface ManageLocationsAction {
  type: ManageLocationsActionTypes;
  payload?: any;
}
