import { ManageLocationsActionTypes, ManageLocationsAction } from '../actions/manageLocationsActionTypes';

interface ManageLocationsState {
  data: string[];
  creating: boolean;
  create_error: string | null;
  updating: boolean;
  update_error: string | null;
}

const initialState: ManageLocationsState = {
  data: [],
  creating: false,
  create_error: null,
  updating: false,
  update_error: null
};

const manageLocationsReducer = (state = initialState, action: ManageLocationsAction): ManageLocationsState => {
  switch (action.type) {
    case ManageLocationsActionTypes.CREATE_LOCATIONS:
      return { ...state, creating: true };
    case ManageLocationsActionTypes.CREATE_LOCATIONS_SUCCESS:
      return { ...state, data: action.payload, creating: false, create_error: null };
    case ManageLocationsActionTypes.CREATE_LOCATIONS_ERROR:
      return { ...state, creating: false, create_error: action.payload };
    case ManageLocationsActionTypes.UPDATE_LOCATIONS:
      return { ...state, updating: true };
    case ManageLocationsActionTypes.UPDATE_LOCATIONS_SUCCESS:
      return { ...state, data: action.payload, updating: false, update_error: null };
      console.log(action.payload);
    case ManageLocationsActionTypes.UPDATE_LOCATIONS_ERROR:
      return { ...state, updating: false, update_error: action.payload };
    default:
      return state;
  }
};

export default manageLocationsReducer;
