// features/createClass/actions/createClassActions.ts
import { getHomePageDataActionTypes, getHomePageDataAction } from './getHomePageDataActionTypes';

export const getHomePageDataRequest = (data: any): getHomePageDataAction => ({
  type: getHomePageDataActionTypes.GET_HOME_PAGE_DATA,
  payload: data
});

export const getHomePageDataRequestSuccess = (data: any): getHomePageDataAction => ({
  type: getHomePageDataActionTypes.GET_HOME_PAGE_DATA_SUCCESS,
  payload: data
});

export const getHomePageDataRequestError = (error: string): getHomePageDataAction => ({
  type: getHomePageDataActionTypes.GET_HOME_PAGE_DATA_ERROR,
  payload: error
});
