import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Avatar,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Stack,
  styled,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Radio
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Link as ViewIcon,
  Delete as DeleteIcon,
  Image as ImageIcon
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  getCourseRequest,
  createCourseRequest,
  updateCourseRequest
} from '../../../features/manageCourse/actions/manageCourseActions';
import { courseDetailRequest } from '../../../features/courseDetail/actions/courseDetailActions';
import { useDispatch, useSelector } from 'react-redux';
import { uploadObject } from '../../../Utils/uploadObject';
import { GetImageURL } from '../../../Components/RenderImage';
import { useNavigate } from 'react-router-dom';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { AppDispatch, AppState } from '../../../store/configureStore';
import ConfirmationModal from '../Components/ConfirmationModal';
import RichTextEditor from '../../../Components/RichText/RichTextEditor';
import { getCategoriesRequest } from '../../../features/manageCategories/actions/manageCategoriesActions';
import { co } from '@fullcalendar/core/internal-common';
import DialogTitleWithClose from '../../../Components/DialogTitleWithClose/DialogTitleWithClose';

const initalCourse={
    course_category: '',
    course_name: '',
    about: '',
    category_image: null,
    content_details: {
      List: [],
      List2: [],
      title: ''
    },
    course_audience: '',
    course_id: '',
    course_image: null,
    details: [],
    description: '',
    subject: '',
    status: 'Active',
    tagline: '',
    course_structure: '',
    course_contact_name: '',
    course_contact_phone: '',
    course_contact_email: '',
    course_detail_url: ''
  }

const NewTutorSignCourses = ({handleSelectPrograms,selectedPrograms}:any) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [courses, setCourses]: any = useState([]);
  const [courseCategories, setCourseCategories]: any = useState([]);
  const [filteredCourses, setFilteredCourses]: any = useState([]);
  const [filterCourse, setFilterCourse]: any = useState('');
  const [newImage, setNewImage]: any = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCourse, setNewCourse]: any = useState(initalCourse);

  const [editingCourse, setEditingCourse]: any = useState(null);
  const [openDialog, setOpenDialog]: any = useState(false);
  const course_list: any = useSelector((state: AppState) => state.courses.data || []);
  const course_list_status: any = useSelector((state: AppState) => state.courses.getting);
  const user_api: any = useSelector((state: AppState) => state.login.account.userAPI);
  const idToken: any = useSelector((state: AppState) => state.login.user.idToken);
  const [imageFile, setImageFile]: any = useState<File | null>(null);
  const [docType, setDocType] = useState('');
  const [courseDetailFile, setCourseDetailFile] = useState<File | null>(null);
  const [courseIdToDelete, setCourseIdToDelete] = useState<number | null>(null);
  const [openAddCourseDialog, setOpenAddCourseDialog] = useState(false); // State to control dialog visibility
  const category_list: any = useSelector((state: AppState) => state.categories.data || []);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Course Contact', 'Course Audience and Detail', 'Course Content', 'Learning Outcome', 'Upload Files']; // Define steps
  const Input = styled('input')({
    display: 'none'
  });

  const user_id = localStorage.getItem('userId');

  const GetCourseDetail: any = (course_name: any, course_category: any) => {
    const course_param: any = {
      course_name: course_name,
      course_category: course_category
    };
    dispatch(courseDetailRequest(course_param));
    navigate('/CourseDetailPage', { state: { selected_course: course_param } });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleLearningChange = (index, value) => {
    const newLearnings = [...newCourse.details];
    newLearnings[index] = value;
    setNewCourse({ ...newCourse, details: newLearnings });
  };

  const handleAddLearning = () => {
    setNewCourse({ ...newCourse, details: [...newCourse.details, ''] });
  };

  const handleRemoveLearning = (index) => {
    const newLearnings = [...newCourse.details];
    newLearnings.splice(index, 1);
    setNewCourse({ ...newCourse, details: newLearnings });
  };

  const handleEditLearningChange = (index, value) => {
    const newLearnings = [...editingCourse.details];
    newLearnings[index] = value;
    setEditingCourse({ ...editingCourse, details: newLearnings });
  };

  const handleEditAddLearning = () => {
    setEditingCourse({ ...editingCourse, details: [...editingCourse.details, ''] });
  };

  const handleEditRemoveLearning = (index) => {
    const newLearnings = [...editingCourse.details];
    newLearnings.splice(index, 1);
    setEditingCourse({ ...editingCourse, details: newLearnings });
  };

  const handleOpenAddCourseDialog = () => {
    setOpenAddCourseDialog(true);
  };

  const handleCloseAddCourseDialog = () => {
    setOpenAddCourseDialog(false);
  };

  useEffect(() => {
    dispatch(
      getCategoriesRequest({
        action: 'get',
        parent_category: 'program_category',
        idToken: idToken,
        user_api: user_api
      })
    );
  }, [dispatch, idToken, user_api]);

  useEffect(() => {
    dispatch(
      getCourseRequest({
        action: 'get',
        parent_category: 'program_category',
        idToken: idToken,
        user_api: user_api
      })
    );
  }, [dispatch, idToken, user_api]);

  useEffect(() => {
    const newCategories = [...courseCategories]; // Create a copy of the current categories
    console.log(category_list.payload);
    for (var i in category_list.payload) {
      if (!newCategories.includes(category_list.payload[i].child_category)) {
        newCategories.push(category_list.payload[i].child_category); // Add only if it's not already included
      }
    }
    setCourseCategories(newCategories); // Set the state once after the loop
  }, [category_list]);
  useEffect(() => {
    if (course_list && !course_list_status) {
      const courses_payload: any = course_list.payload;
      console.log('courses_payload', courses_payload);

      for (var i in courses_payload) {
        const newId = Math.max(...courses_payload?.map((c) => c.id), 0) + 1;
        setCourses([...courses_payload, { id: newId, ...courses_payload }]);
        setFilteredCourses([...courses_payload, { id: newId, ...courses_payload }]);
      }
    }
  }, [course_list, course_list_status]);

  const handleAddCourse = () => {
    if (imageFile) {
      uploadObject({ file: imageFile, image_type: 'course_images', upload: true });
      newCourse.course_image = 'course_images/' + imageFile.name;
    }
    if (courseDetailFile) {
      uploadObject({ file: courseDetailFile, image_type: 'course_detail', upload: true });
      newCourse.course_detail_url = 'course_detail/' + courseDetailFile.name;
    }
    dispatch(
      createCourseRequest({
        action: 'create',
        user_id:user_id,
        course_data: newCourse,
        idToken: idToken,
        user_api: user_api
      })
    );
    setNewCourse(initalCourse);
    setImageFile(null);
    setActiveStep(null)
    if (newCourse.course_category.trim() && newCourse.subject.trim()) {
      setCourses([...courses, { ...newCourse }]);
      setFilteredCourses([...filteredCourses, { ...newCourse }]);
      handleCloseAddCourseDialog();
    }
  };

  const handleAddToList = () => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List: [...prevCourse.content_details.List, '']
      }
    }));
  };

  const handleRemoveFromList = (index) => {
    const newList = [...newCourse.content_details.List];
    newList.splice(index, 1);
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List: newList
      }
    }));
  };

  const handleListChange = (index, value) => {
    setNewCourse((prevCourse) => {
      const updatedList = [...prevCourse.content_details.List];
      updatedList[index] = value;
      return {
        ...prevCourse,
        content_details: {
          ...prevCourse.content_details,
          List: updatedList
        }
      };
    });
  };

  const handleAddToList2 = () => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List2: [...prevCourse.content_details.List2, '']
      }
    }));
  };

  const handleRemoveFromList2 = (index) => {
    const newList2 = [...newCourse.content_details.List2];
    newList2.splice(index, 1);
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List2: newList2
      }
    }));
  };

  const handleList2Change = (index, value) => {
    setNewCourse((prevCourse) => {
      const updatedList2 = [...prevCourse.content_details.List2];
      updatedList2[index] = value;
      return {
        ...prevCourse,
        content_details: {
          ...prevCourse.content_details,
          List2: updatedList2
        }
      };
    });
  };

  const handleTitleChange = (e) => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        title: e.target.value
      }
    }));
  };

  const handleCourseStructureChange = (content: string) => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      content_details: { ...prevCourse.content_details, course_structure: content }
    }));
  };

  const handleAudienceChange = (e, value) => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      course_audience: [...prevCourse.course_audience, value]
    }));
  };

  const handleEditAddToList = () => {
    setEditingCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List: [...prevCourse.content_details.List, '']
      }
    }));
  };

  const handleEditRemoveFromList = (index) => {
    const newList = [...newCourse.content_details.List];
    newList.splice(index, 1);
    setEditingCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List: newList
      }
    }));
  };

  const handleEditListChange = (index, value) => {
    setEditingCourse((prevCourse) => {
      const updatedList = [...prevCourse.content_details.List];
      updatedList[index] = value;
      return {
        ...prevCourse,
        content_details: {
          ...prevCourse.content_details,
          List: updatedList
        }
      };
    });
  };

  const handleEditAddToList2 = () => {
    setEditingCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List2: [...prevCourse.content_details.List2, '']
      }
    }));
  };

  const handleEditRemoveFromList2 = (index) => {
    const newList2 = [...newCourse.content_details.List2];
    newList2.splice(index, 1);
    setEditingCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        List2: newList2
      }
    }));
  };

  const handleEditList2Change = (index, value) => {
    setEditingCourse((prevCourse) => {
      const updatedList2 = [...prevCourse.content_details.List2];
      updatedList2[index] = value;
      return {
        ...prevCourse,
        content_details: {
          ...prevCourse.content_details,
          List2: updatedList2
        }
      };
    });
  };

  const handleEditTitleChange = (e) => {
    setEditingCourse((prevCourse) => ({
      ...prevCourse,
      content_details: {
        ...prevCourse.content_details,
        title: e.target.value
      }
    }));
  };

  const handleUpdateCourse = () => {
    if (editingCourse && editingCourse.course_category.trim()) {
      setCourses((prevCourse) => {
        const newCourse = prevCourse.map((c) => {
          if (c.id === editingCourse?.id) {
            return { ...editingCourse };
          }
          return c;
        });
        return newCourse;
      });
      setFilteredCourses((prevCourse) => {
        const newCourse = prevCourse.map((c) => {
          if (c.id === editingCourse?.id) {
            return { ...editingCourse };
          }
          return c;
        });
        return newCourse;
      });
      let current_image_file;
      let content_detail_full_file_name;
      if (imageFile) {
        current_image_file = 'course_images/' + imageFile.name;
      } else {
        current_image_file = editingCourse.course_image;
      }
      if (courseDetailFile) {
        content_detail_full_file_name = 'course_detail/' + courseDetailFile.name;
        uploadObject({ file: courseDetailFile, image_type: 'course_detail', upload: true });
      } else {
        content_detail_full_file_name = editingCourse.course_detail_url;
      }

      console.log('editingCourse', editingCourse);

      dispatch(
        updateCourseRequest({
          action: 'update',
          course_data: {
            id: editingCourse.id,
            course_id: editingCourse.course_id,
            course_category: editingCourse.prev_course_category,
            course_name: editingCourse.prev_course_name,
            about: editingCourse.about,
            category_image: editingCourse.category_image,
            content_details: editingCourse.content_details,
            details: editingCourse.details,
            course_audience: editingCourse.course_audience,
            subject: editingCourse.subject,
            tagline: editingCourse.tagline,
            course_structure: editingCourse.course_structure,
            description: editingCourse.description,
            course_image: editingCourse.prev_course_image,
            new_course_image: current_image_file,
            status: editingCourse.status,
            new_course_category: editingCourse.course_category,
            new_course_name: editingCourse.course_name,
            course_contact_name: editingCourse.course_contact_name,
            course_contact_phone: editingCourse.course_contact_phone,
            course_contact_email: editingCourse.course_contact_email,
            course_detail_url: editingCourse.prev_course_detail_url,
            new_course_detail_url: content_detail_full_file_name,
            course_created_by: user_id,
            course_owner_id : user_id,
            course_co_owner_id :  user_id
          },
          idToken: idToken,
          user_api: user_api
        })
      );
      setEditingCourse(null);
      setImageFile(null);
      setOpenDialog(false);
    } else {
      console.error('Invalid editing course:', editingCourse);
    }
  };
  const handleDeleteCourse = (id: number) => {
    setCourseIdToDelete(id);
    setIsModalOpen(true);
  };
  const confirmDelete = () => {
    if (courseIdToDelete !== null) {
      const courseToDelete = courses.find((course) => course.id === courseIdToDelete);
      if (courseToDelete) {
        dispatch(
          updateCourseRequest({
            action: 'delete',
            course_data: {
              id: courseIdToDelete,
              course_category: courseToDelete.course_category,
              course_name: courseToDelete.course_name,
              image: courseToDelete.course_image
            },
            idToken: idToken,
            user_api: user_api
          })
        );

        setCourses((prevCourses) => prevCourses.filter((course) => course.id !== courseIdToDelete));
        setFilteredCourses((prevCourses) => prevCourses.filter((course) => course.id !== courseIdToDelete));
      }
    }
    setIsModalOpen(false);
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setCourseIdToDelete(null);
  };

  useEffect(() => {
    if (filterCourse) {
      setFilteredCourses(courses.filter((cat) => cat.course_category === filterCourse));
    } else {
      setFilteredCourses(courses);
    }
  }, [filterCourse, courses]);

  const handleFilterChange = (event) => {
    setFilterCourse(event.target.value);
  };

  useEffect(() => {
    if (openDialog && editingCourse) {
      // Ensure all fields are populated when dialog opens
      setEditingCourse((prevState) => ({
        ...prevState,
        id: prevState.id,
        course_id: prevState.course_id || '',
        course_category: prevState.course_category || '',
        course_name: prevState.course_name || '',
        course_audience: prevState.course_audience || '',
        about: prevState.about || '',
        details: prevState.details || [],
        category_image: prevState.category_image || null,
        content_details: prevState.content_details || {},
        subject: prevState.subject || '',
        status: prevState.status || '',
        description: prevState.description || '',
        course_image: prevState.course_image || null,
        prev_course_image: prevState.course_image || null,
        tagline: prevState.tagline || '',
        course_structure: prevState.course_structure || '',
        prev_course_category: prevState.course_category || '',
        prev_course_name: prevState.course_name || '',
        course_contact_name: prevState.course_contact_name || '',
        course_contact_phone: prevState.course_contact_phone || '',
        course_contact_email: prevState.course_contact_email || '',
        course_detail_url: prevState.course_detail_url || '',
        prev_course_detail_url: prevState.course_detail_url || ''
      }));
    } else if (!openDialog) {
      // Reset editingCourse when dialog closes
      setEditingCourse(null);
    }
  }, [openDialog]);

  const openEditDialog = (index, course) => {
    course['id'] = index;
    setEditingCourse({ ...course });
    setOpenDialog(true);
    setNewImage(null);
  };

  const image_upload_function = (file, isNewCourse = false, image_category) => {
    setImageFile(file);
    if (file) {
      uploadObject({ file: file, image_type: 'course_images', upload: true });

      const reader = new FileReader();
      reader.onloadend = () => {
        if (isNewCourse) {
          setNewCourse({ ...newCourse, [image_category]: 'course_images/' + file.name });
          setNewImage(reader.result);
        } else {
          setEditingCourse({ ...editingCourse, [image_category]: 'course_images/' + file.name });
          setNewImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
      setImageFile(file);
    }
  };

  const handleNewImageUpload = (event) => {
    const file = event.target.files[0];
    image_upload_function(file, true, 'course_cover_image');
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    image_upload_function(file, false, 'course_cover_image');
  };

  const handleCourseDetailUpload = () => {
    setDocType('course_detail');
    uploadObject({ file: courseDetailFile, image_type: 'course_detail', upload: true });
  };

  const handleEditCourseDetailUpload = () => {
    setDocType('course_detail');
    uploadObject({ file: courseDetailFile, image_type: 'course_detail', upload: true });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setCourseDetailFile(selectedFile);
    if (selectedFile) {
      setDocType('course_detail');
      newCourse.course_detail_url = selectedFile.name;
    }
  };

  const handleEditFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setCourseDetailFile(selectedFile);
    if (selectedFile) {
      setDocType('course_detail');
      editingCourse.course_detail_url = selectedFile.name;
    }
  };

  const CourseAvatar = ({ imageName }) => {
    console.log('get request')

    const imageUrl: any =imageName?  GetImageURL({
      object_name: imageName?.replace('course_images/', ''),
      presigned: false,
      folder: 'public/course_images'
    }):"";

    return (
      <Avatar
        src={imageUrl}
        sx={{
          width: '4rem',
          height: '4rem'
        }}
      >
        <ImageIcon />
      </Avatar>
    );
  };

  const CourseImageBox = ({ imageName }) => {
    const imageUrl: any =imageName?GetImageURL({
      object_name: imageName?.replace('course_images/', ''),
      presigned: false,
      folder: 'public/course_images'
    }):"";

    return (
      <Box sx={{ mb: 2 }}>
        <img src={imageUrl} alt={imageName} style={{ maxWidth: '100%', maxHeight: '200px' }} />
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', p: 5, mt: 5 }}>
      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h3" gutterBottom sx={{ ml: 15, mr: 2,whiteSpace:"nowrap" }}>
         Select Course To Teach
        </Typography>
        <Button
          key="handle_add_course_button"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddCourseDialog}
        >
          Add Course
        </Button>
      </Box>

      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel key="input_label1" id="filter-course-label">
          Filter by Program Course
        </InputLabel>
        <Select
          labelId="filter-course-label"
          id="filter-course"
          value={filterCourse}
          label="Filter by Program Course"
          onChange={handleFilterChange}
        >
          <MenuItem value="">
            <em>All Course</em>
          </MenuItem>
          {Array.from(new Set(courses?.map((cat) => cat?.parent_category))).map((course: any, index: any) => (
            <MenuItem key={index} value={course}>
              {course}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Dialog fullWidth maxWidth="lg" open={openAddCourseDialog} onClose={handleCloseAddCourseDialog}>
        <DialogTitleWithClose title={'Add New Course'} handleClose={handleCloseAddCourseDialog} />
        <Box sx={{ maxWidth: 800, margin: 'auto', p: 5, mt: 5 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <DialogContent>
            {/* Step 1: Course Contact */}
            {activeStep === 0 && (
              <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="course-category-label">Course Category</InputLabel>
                    <Select
                      labelId="course-category-label"
                      value={newCourse.course_category}
                      onChange={(e) => setNewCourse({ ...newCourse, course_category: e.target.value })}
                    >
                      {courseCategories.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="course-subject-label">Course Subject</InputLabel>
                    <Select
                      labelId="course-subject-label"
                      value={newCourse.subject}
                      onChange={(e) => setNewCourse({ ...newCourse, subject: e.target.value })}
                    >
                      <MenuItem value="Math">Math</MenuItem>
                      <MenuItem value="Language Arts">Language Arts</MenuItem>
                      <MenuItem value="Foreign Language">Foreign Language</MenuItem>
                      <MenuItem value="Science">Science</MenuItem>
                      <MenuItem value="Programming">Programming</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Course Title"
                    value={newCourse.course_name}
                    onChange={(e) => setNewCourse({ ...newCourse, course_name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Course Tagline"
                    value={newCourse.tagline}
                    onChange={(e) => setNewCourse({ ...newCourse, tagline: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Course Contact Name"
                    value={newCourse.course_contact_name}
                    onChange={(e) => setNewCourse({ ...newCourse, course_contact_name: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Course Contact Phone"
                    value={newCourse.course_contact_phone}
                    onChange={(e) => setNewCourse({ ...newCourse, course_contact_phone: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Course Contact Email"
                    value={newCourse.course_contact_email}
                    onChange={(e) => setNewCourse({ ...newCourse, course_contact_email: e.target.value })}
                  />
                </Grid>
              </Grid>
            )}

            {/* Step 2: Course Content */}
            {activeStep === 1 && (
              <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Course Audience</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="K-12 Students"
                        checked={newCourse.course_audience.includes('K-12 Students')}
                        onChange={(e) => handleAudienceChange(e, 'K-12 Students')}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Students in College"
                        checked={newCourse.course_audience.includes('Students in College')}
                        onChange={(e) => handleAudienceChange(e, 'Students in College')}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Adults getting back to school"
                        checked={newCourse.course_audience.includes('Adults getting back to school')}
                        onChange={(e) => handleAudienceChange(e, 'Adults getting back to school')}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="About Course"
                    multiline
                    rows={5}
                    value={newCourse.about}
                    onChange={(e) => setNewCourse({ ...newCourse, about: e.target.value })}
                  />
                </Grid>
              </Grid>
            )}

            {/* Step 3: Course Audience */}
            {activeStep === 2 && (
              <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Course Content Title"
                    value={newCourse.content_details.title}
                    onChange={handleTitleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Course Content
                  </Typography>
                  <RichTextEditor
                    onChange={handleCourseStructureChange} // Update the onChange handler to handle RichTextEditor changes
                  />
                </Grid>
              </Grid>
            )}
            {activeStep === 3 && (
              <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
                {/* Dynamic Learning Inputs */}
                <Grid item xs={12} sm={12}>
                  <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddLearning}>
                    Add upto 5 Learning Objectives
                  </Button>
                </Grid>
                {newCourse.details.map((learning, index) => (
                  <Grid item xs={12} sm={12} key={index}>
                    <Box display="flex" alignItems="center">
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={`Objective ${index + 1}`}
                        value={learning}
                        onChange={(e) => handleLearningChange(index, e.target.value)}
                      />
                      <IconButton onClick={() => handleRemoveLearning(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
                {newCourse.content_details.List.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box display="flex" alignItems="center">
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={`Structure Detail ${index + 1}`}
                        value={item}
                        onChange={(e) => handleListChange(index, e.target.value)}
                      />
                      <IconButton onClick={() => handleRemoveFromList(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddToList}>
                    Structure Detail
                  </Button>
                </Grid>

                {newCourse.content_details.List2.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box display="flex" alignItems="center">
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={`Additional Note ${index + 1}`}
                        value={item}
                        onChange={(e) => handleList2Change(index, e.target.value)}
                      />
                      <IconButton onClick={() => handleRemoveFromList2(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddToList2}>
                    Additional Notes
                  </Button>
                </Grid>
              </Grid>
            )}
            {/* Step 5: Upload Files */}
            {activeStep === 4 && (
              <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
                <Grid item xs={12} sm={4}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="new-course-image"
                    type="file"
                    onChange={handleNewImageUpload}
                  />
                  <label htmlFor="new-course-image">
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ backgroundColor: 'gray' }}
                      startIcon={<ImageIcon />}
                    >
                      Upload Course Cover Image
                    </Button>
                  </label>
                  {newCourse.course_image && (
                    <Box sx={{ mt: 2 }}>
                      <img
                        src={newImage}
                        alt={newCourse.course_image}
                        style={{ maxWidth: '100%', maxHeight: '100px' }}
                      />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={4}>
                  <div>
                    <Input
                      accept="application/pdf"
                      id="icon-button-file1"
                      name="icon-button-file1"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="icon-button-file1">
                      {newCourse?.course_detail_url?.length > 0 ? (
                        <label>New Uploaded: {newCourse?.course_detail_url}</label>
                      ) : (
                        <label>Upload New Course Syllabus</label>
                      )}
                      <IconButton component="span" color="primary" onChange={handleCourseDetailUpload}>
                        <CloudUploadTwoToneIcon sx={{ fontSize: '34px' }} />
                      </IconButton>
                    </label>
                  </div>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddCourseDialog}>Cancel</Button>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? (
                <Button onClick={handleAddCourse} variant="contained">
                  Add Course
                </Button>
              ) : (
                'Next'
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <List>
  {filteredCourses.length > 0 &&
    filteredCourses?.map((course_detail, index) =>
      course_detail.parent_category &&(
 
          <ListItem
            key={`courses_${index}`}
            secondaryAction={
              <Box>
                {
                  course_detail?.course_co_owner_id === user_id && (
                    <IconButton edge="end" onClick={() => openEditDialog(index, course_detail)}>
                    <EditIcon />
                  </IconButton>
                  )
                }
                <IconButton
                  edge="end"
                  onClick={() =>
                    GetCourseDetail(course_detail.course_name, course_detail.course_category, course_detail.id)
                  }
                >
                  <ViewIcon />
                </IconButton>
                <Radio
                  checked={selectedPrograms?.findIndex((item) => item.id === course_detail.course_id)!==-1}
                  onClick={()=>handleSelectPrograms(course_detail)}
                  value={course_detail.id}
                  name="course-radio-button"
                  inputProps={{ 'aria-label': course_detail.course_name }}
                />
              </Box>
            }
          >
            {isModalOpen && (
              <ConfirmationModal
                message="Are you sure you want to delete this course?"
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
                open={isModalOpen}
              />
            )}

            {imageFile ? (
              <CourseAvatar imageName={imageFile.name} />
            ) : (
              <CourseAvatar imageName={course_detail?.course_image} />
            )}
            <ListItemText
              primary={`${course_detail.course_category} - ${course_detail.course_name}`}
              secondary={course_detail.tagline}
              primaryTypographyProps={{ fontSize: '1.2rem' }}
              secondaryTypographyProps={{ fontSize: '0.9rem' }}
              sx={{ ml: 2 }}
            />
          </ListItem>

      ) 
    )}
</List>


      <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitleWithClose title="Edit Course" handleClose={() => setOpenDialog(false)} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Course Title"
                fullWidth
                variant="outlined"
                value={editingCourse?.course_name || ''}
                onChange={(e) => setEditingCourse({ ...editingCourse, course_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Course Category"
                fullWidth
                variant="outlined"
                value={editingCourse?.course_category || ''}
                onChange={(e) => setEditingCourse({ ...editingCourse, course_category: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Course Subject"
                value={editingCourse?.subject || ''}
                onChange={(e) => setEditingCourse({ ...editingCourse, subject: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Course Tagline"
                fullWidth
                variant="outlined"
                value={editingCourse?.tagline || ''}
                onChange={(e) => setEditingCourse({ ...editingCourse, tagline: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Course Contact Name"
                value={editingCourse?.course_contact_name}
                onChange={(e) => setEditingCourse({ ...editingCourse, course_contact_name: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Course Contact Phone"
                value={editingCourse?.course_contact_phone}
                onChange={(e) => setEditingCourse({ ...editingCourse, course_contact_phone: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Course Contact Email"
                value={editingCourse?.course_contact_email}
                onChange={(e) => setEditingCourse({ ...editingCourse, course_contact_email: e.target.value })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="About Course"
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                value={editingCourse?.about || ''}
                onChange={(e) => setEditingCourse({ ...editingCourse, about: e.target.value })}
              />
            </Grid>
            {/* Dynamic Learning Inputs */}
            <Grid item xs={12}>
              <Button variant="outlined" startIcon={<AddIcon />} onClick={handleEditAddLearning}>
                Add What Students Will Learn
              </Button>
            </Grid>
            {editingCourse?.details?.map((learning, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={`Objective ${index + 1}`}
                    value={learning}
                    onChange={(e) => handleEditLearningChange(index, e.target.value)}
                  />
                  <IconButton onClick={() => handleEditRemoveLearning(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
            <Paper elevation={3} sx={{ width: '100%', padding: 2, marginBottom: 3 }}>
              <Stack spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Content Title"
                    value={editingCourse?.content_details.title}
                    onChange={handleEditTitleChange}
                  />
                </Grid>
                <Stack direction={'row'} spacing={3}>
                  <Grid item xs={6}>
                    {/* List 1 - What students will learn */}
                    {editingCourse?.content_details.List.map((list_item, index) => (
                      <Grid item xs={12} key={index} sx={{ pt: 2 }}>
                        <Box display="flex" alignItems="center">
                          <TextField
                            fullWidth
                            variant="outlined"
                            label={`Structure Detail ${index + 1}`}
                            value={list_item}
                            onChange={(e) => handleEditListChange(index, e.target.value)}
                          />
                          <IconButton onClick={() => handleEditRemoveFromList(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}

                    <Grid item xs={12} sx={{ pt: 2 }}>
                      <Button variant="outlined" startIcon={<AddIcon />} onClick={handleEditAddToList}>
                        Add Structure Detail
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {/* List 2 - Additional items (e.g., prerequisites) */}
                    {editingCourse?.content_details.List2.map((item, index) => (
                      <Grid item xs={12} key={index} sx={{ pt: 2 }}>
                        <Box display="flex" alignItems="center">
                          <TextField
                            fullWidth
                            variant="outlined"
                            label={`Additional Notes ${index + 1}`}
                            value={item}
                            onChange={(e) => handleEditList2Change(index, e.target.value)}
                          />
                          <IconButton onClick={() => handleEditRemoveFromList2(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}

                    <Grid item xs={6} sx={{ pt: 2 }}>
                      <Button variant="outlined" startIcon={<AddIcon />} onClick={handleEditAddToList2}>
                        Add Additional Notes
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Paper>
            <Grid item xs={6}>
              {editingCourse?.course_image && (
                <Box sx={{ mb: 2 }}>
                  {newImage ? (
                    <img src={newImage} alt={newImage} style={{ maxWidth: '100%', maxHeight: '200px' }} />
                  ) : (
                    <CourseImageBox imageName={editingCourse?.course_image} />
                  )}
                </Box>
              )}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="edit-course-image"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="edit-course-image">
                <Button variant="contained" component="span" startIcon={<ImageIcon />}>
                  {editingCourse?.course_image ? 'Update Course Cover Image' : 'Upload Course Cover Image'}
                </Button>
              </label>
            </Grid>

            <Grid item xs={6}>
              <div>
                <Input
                  accept="application/pdf"
                  id="icon-button-file"
                  name="icon-button-file"
                  type="file"
                  onChange={handleEditFileChange}
                />
                <label htmlFor="icon-button-file">
                  {editingCourse?.course_detail_url?.length > 0 ? (
                    <label>Edit Uploaded Course Syllabus: {editingCourse?.course_detail_url}</label>
                  ) : (
                    <label>Edit Course Syllabus</label>
                  )}
                  <IconButton component="span" color="primary" onChange={handleEditCourseDetailUpload}>
                    <CloudUploadTwoToneIcon sx={{ fontSize: '34px' }} />
                  </IconButton>
                </label>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => handleUpdateCourse()} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default NewTutorSignCourses;
