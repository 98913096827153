import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { GetClassStudentsActionTypes } from '../actions/getClassStudentsActionTypes';
import { getClassStudentsError, getClassStudentsSuccess } from '../actions/getClassStudentsActions';

function* getClassStudentsSaga(req) {
  try {
    const response = yield call(apiService.enrollClass, req.payload);
    yield put(getClassStudentsSuccess(response?.data));
  } catch (error: any) {
    yield put(getClassStudentsError(error.message));
  }
}

export function* watchGetClassStudents() {
  yield takeLatest(GetClassStudentsActionTypes.CLASS_STUDENTS_REQUEST, getClassStudentsSaga);
}
